<template>
  <v-container class="group-transparent">
    <component v-for="row in element.data" :key="row.id" :element="row" :is="getComponent(row.type)" />
  </v-container>
</template>
<script>
import { ComponentDetectionMixin } from '../mixins'

export default {
  name: 'view-group-transparent',
  mixins: [ComponentDetectionMixin],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss">
.group-transparent {
  padding-left: 0;
  padding-right: 0;
  margin-right: unset;
  margin-left: unset;
}
</style>
