import orderAPI from '@/api/orderAPI'
import { Alerts } from '@/plugins/alerts'
import dayjs from 'dayjs'

const MUTATION_TYPES = {
  ADD_NOTE: 'ADD_NOTE',
  SET_NOTES: 'SET_NOTES',
  SET_LOADING: 'SET_LOADING',
}

const state = () => ({
  notes: [],
  loading: false,
})

const mapFrom = (user) => (n) => {
  return {
    ...n,
    companyName: '',
    authorName: user.id == n.user_id ? 'Вы' : user.role == 'agent' ? `Страховщик ${n.user_id}` : `Агент ${n.user_id}`,
    createdAt: dayjs(n.created_at).format('DD.MM.YYYY, HH:mm'),
  }
}

const mutations = {
  [MUTATION_TYPES.SET_LOADING](state, loading) {
    state.loading = loading
  },

  [MUTATION_TYPES.SET_NOTES](state, notes) {
    state.notes = notes
  },

  [MUTATION_TYPES.ADD_NOTE](state, note) {
    state.notes = [...state.notes, note]
  },
}

const actions = {
  async getNotes({ commit, rootState }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const { user } = rootState.user
      const { view } = rootState.orderView
      const { data } = await orderAPI.getAllNotesForFormById(view.id)

      const notes = data.map(mapFrom(user))

      commit(MUTATION_TYPES.SET_NOTES, notes)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки примечаний', error })
    }
    commit(MUTATION_TYPES.SET_LOADING, false)
  },

  async createNote({ commit, rootState }, { rowId, text }) {
    commit(MUTATION_TYPES.SET_LOADING, true)

    try {
      const { user } = rootState.user
      const { view } = rootState.orderView

      const { data } = await orderAPI.createNoteForRow({ formId: view.id, rowId, userId: user.id, text })
      const note = mapFrom(user)(data)

      commit(MUTATION_TYPES.ADD_NOTE, note)
    } catch (error) {
      Alerts.error({ message: 'Не удалось создать примечание', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, false)
  },
}

const getters = {
  notes({ notes }) {
    return notes
  },

  usersIds({ notes }, getters, rootState) {
    const uniqUserIdsSet = notes.reduce((prev, curr) => {
      prev.add(curr.user_id)
      return prev
    }, new Set())

    const { user } = rootState.user
    uniqUserIdsSet.delete(user.id)
    return [...uniqUserIdsSet.values()]
  },

  notesByRowId({ notes }) {
    return (rowId) => notes.filter((n) => n.form_row_id == rowId)
  },

  notesByRowAndInsurerId({ notes }, getters, rootState) {
    const { user } = rootState.user
    return (rowId, insurerId) =>
      notes.filter((n) => n.form_row_id == rowId && (n.user_id == insurerId || n.user_id == user.id))
  },

  loading({ loading }) {
    return loading
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
