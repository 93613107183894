<template>
  <v-row class="mb-3" v-if="!notesLoading && notesByRowId(element.id).length > 0">
    <v-col cols="4" class="mr-3" :class="{ 'font-weight-bold': element.is_bold_name }"> {{ element.name }} </v-col>

    <ChatDropdown :row="element">
      <v-col>{{ flattedData }} </v-col>
    </ChatDropdown>
  </v-row>
</template>
<script>
import ChatDropdown from '@/views/offers-insurer/components/ChatDropdown.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'view-comments-row',
  components: { ChatDropdown },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },

  computed: {
    flattedData() {
      return (this.element.data || []).flat().join(', ')
    },

    ...mapGetters({
      notesByRowId: 'notes/notesByRowId',
      notesLoading: 'notes/loading',
    }),
  },
}
</script>
