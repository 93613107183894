import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#039A9A',
        accent: '#E72612',
      },
    },
  },
  icons: {
    values: {
      radioOn: 'mdi-checkbox-marked-circle',
      radioOff: 'mdi-checkbox-blank-circle-outline',
      dropdown: 'mdi-chevron-down',
    },
  },
})
