<template>
  <div class="comments-group-three">
    <v-subheader v-if="element.header" class="pa-0 font-weight-bold mb-2 ml-n2"> {{ element.header }} </v-subheader>
    <v-container fluid class="list__zebra">
      <component v-for="row in element.data" :key="row.id" :element="row" :is="getComponent(row.type)" />
    </v-container>
  </div>
</template>
<script>
import { ComponentDetectionMixin } from '../mixins'

export default {
  name: 'view-comments-group-three',
  mixins: [ComponentDetectionMixin],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss">
.comments-group-three {
  .list__zebra {
    .row:nth-child(odd) {
      .col {
        background-color: #f4f7f9;
      }
    }
  }
}
</style>
