import profileAPI from '@/api/profileAPI'

const mutationTypes = {
  SET_USER_DATA: 'SET_USER_DATA',
  SET_LOADING: 'SET_LOADING',
}

const state = () => ({
  loading: false,
  user: {
    email: '',
    role: 'guest',
  },
})

const mutations = {
  [mutationTypes.SET_USER_DATA](state, data) {
    state.user = { ...data }
  },
  [mutationTypes.SET_LOADING](state, loading = false) {
    state.loading = loading
  },
}

const actions = {
  async getUserData({ commit, state }) {
    commit(mutationTypes.SET_LOADING, true)

    try {
      const { data } = await profileAPI.getProfileInfo()

      commit(mutationTypes.SET_USER_DATA, { ...state.user, ...data })
    } catch (error) {
      console.error(error)
    }

    commit(mutationTypes.SET_LOADING, false)
  },

  resetData({ commit }) {
    commit(mutationTypes.SET_USER_DATA, state())
  },
}

const getters = {
  data: ({ user }) => user,
  isAuthorized: ({ user }) => user.role != 'guest',
  hasRole:
    ({ user }) =>
    (roleOrRoles) => {
      if (Array.isArray(roleOrRoles)) {
        return roleOrRoles.includes(user.role)
      }

      return user.role == roleOrRoles
    },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
