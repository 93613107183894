<template>
  <v-alert border="left" :type="alert.type" dismissible @close="removeOne(alert)"> {{ alert.message }} </v-alert>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    alert: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    if (this.alert.type != 'error') {
      this.selfDestroy()
    }
  },
  methods: {
    selfDestroy() {
      setTimeout(() => {
        this.removeOne(this.alert)
      }, this.alert.duration * 1000)
    },
    ...mapMutations({
      removeOne: 'alerts/REMOVE_ONE',
    }),
  },
}
</script>
<style lang="scss">
.v-alert.v-sheet {
  position: relative;
  margin-bottom: 20px;
  min-width: 300px;
  margin-right: 50px;
  pointer-events: all;
}
</style>
