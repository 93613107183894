<template>
  <v-radio-group
    class="my-2"
    v-model="value"
    :column="element.elements.length > 2"
    name="legal"
    :append-icon="isDisabled ? 'mdi-lock' : ''"
  >
    <v-radio
      v-for="el in element.elements"
      :key="el.uuid"
      :label="el.title"
      :value="el.title"
      :disabled="el.disabled"
      @change="submit(el)"
    />
  </v-radio-group>
</template>
<script>
import debounce from 'lodash.debounce'

export default {
  name: 'form-radio',
  inject: ['moduleName'],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: '',
    }
  },
  created() {
    const checkedElement = this.element.elements.find((el) => el.is_checked)
    if (checkedElement?.title) {
      this.value = checkedElement.title
    }
  },
  methods: {
    submit(el) {
      const action = this.getActionByUUID(el.uuid)

      if (action) {
        this.executeAction(action)
      }
    },

    executeAction(action) {
      this.$store.dispatch(`${this.moduleName}/executeAction`, action)
    },

    updateElement(el) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, el)
    },
  },

  computed: {
    getActionByUUID() {
      return this.$store.getters[`${this.moduleName}/getActionByUUID`]
    },

    isDisabled() {
      return this.element.elements.every((e) => e.disabled)
    },
  },

  watch: {
    value(newValue) {
      this.debouncedWatch(newValue)
    },
  },

  mounted() {
    this.debouncedWatch = debounce((newValue) => {
      for (const el of this.element.elements) {
        if (el.title == newValue) {
          this.updateElement({ uuid: el.uuid, key: 'is_checked', value: true })
        } else {
          this.updateElement({ uuid: el.uuid, key: 'is_checked', value: false })
        }
      }
    }, 500)
  },

  beforeDestroy() {
    this.debouncedWatch.cancel()
  },
}
</script>
<style lang="scss">
.v-radio {
  margin-right: 20px;

  &:last-of-type {
    margin-right: auto;
  }
}
</style>
