const state = () => ({
  sidebarIsOpen: false,
})

const mutations = {
  toggleSideBar(state) {
    state.sidebarIsOpen = !state.sidebarIsOpen
  },
}

const getters = {
  sidebarIsOpen: (s) => s.sidebarIsOpen,
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
