<template>
  <v-row v-if="!element.is_hidden">
    <v-col>
      <h6 class="text-h6 font-weight-bold mb-4">{{ element.name }}</h6>
      <v-divider />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-row-header',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
