import Vue from 'vue'

const statuses = {
  0: 'На рассмотрении',
  1: 'Отменен',
  2: 'Отказано',
  3: 'Подвержден',
  4: 'В архиве',
}

function getOfferStatusText(statusId) {
  if (statusId in statuses) {
    return statuses[statusId]
  }

  return '-'
}

Vue.filter('getOfferStatusText', getOfferStatusText)
