import Vue from 'vue'

import ViewGroupOneVue from './groups/ViewGroupOne.vue'
import ViewGroupTwoVue from './groups/ViewGroupTwo.vue'
import ViewGroupThree from './groups/ViewGroupThree.vue'
import ViewGroupTransparent from './groups/ViewGroupTransparent.vue'
import ViewCommentsRow from './rows/ViewCommentsRow.vue'
import ViewCommentsBlock from './ViewCommentsBlock.vue'

Vue.component('view-comments-group-one', ViewGroupOneVue)
Vue.component('view-comments-group-two', ViewGroupTwoVue)
Vue.component('view-comments-group-three', ViewGroupThree)
Vue.component('view-comments-group-transparent', ViewGroupTransparent)
Vue.component('view-comments-row', ViewCommentsRow)
Vue.component('view-comments-block', ViewCommentsBlock)
