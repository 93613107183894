import uniqueId from 'lodash/uniqueId'

const state = () => ({
  data: [],
})

const mutations = {
  ADD_ONE(state, notification) {
    const id = uniqueId('alert_')
    const duration = notification.duration || 5
    state.data = [...state.data, { ...notification, id, duration }]
  },

  REMOVE_ONE(state, { id }) {
    state.data = state.data.filter((n) => n.id != id)
  },
}

const getters = {
  data({ data }) {
    return data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
