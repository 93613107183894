<template>
  <v-app id="app">
    <router-view />
    <AlertsContainer />
  </v-app>
</template>
<script>
import AlertsContainer from '@/components/alerts/AlertsContainer.vue'
export default {
  components: { AlertsContainer },
}
</script>
<style lang="scss">
// @import '@/assets/styles/main';
</style>
