<template>
  <form-group-one v-if="element.type == Groups.TYPE_1" :element="element" />
  <form-group-two v-else-if="element.type == Groups.TYPE_2" :element="element" />
  <form-group-three v-else-if="element.type == Groups.TYPE_3" :element="element" />
  <form-group-transparent v-else-if="element.type == Groups.TYPE_TRANSPARENT" :element="element" />
</template>
<script>
import { Inputs, Rows, Groups } from './types'
import { detectComponent } from './filters'
import FormGroupOne from './groups/FormGroupOne'
import FormGroupTwo from './groups/FormGroupTwo'
import FormGroupThree from './groups/FormGroupThree'
import FormGroupTransparent from './groups/FormGroupTransparent'

export default {
  name: 'form-block',
  components: {
    FormGroupOne,
    FormGroupTwo,
    FormGroupThree,
    FormGroupTransparent,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  filters: {
    detectComponent,
  },
  data() {
    return {
      Inputs,
      Rows,
      Groups,
    }
  },
}
</script>

<style lang="scss" scoped>
.elem {
  color: #000;
  border: 1px solid #000;
  padding: 10px;
  margin-bottom: 5px;
}
</style>
