import orderAPI from '@/api/orderAPI'
import debounce from 'lodash.debounce'
import _ from 'lodash'

import formRules from '../form-rules'

export const updateValueMixin = (watchKey, valueKey) => ({
  inject: ['moduleName'],

  watch: {
    [watchKey](newValue) {
      this.debouncedWatch(newValue)
    },
  },

  methods: {
    updateElement(action) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, action)
    },
  },

  created() {
    this.debouncedWatch = debounce((newValue) => {
      this.updateElement({ uuid: this.element.uuid, key: valueKey, value: newValue })
    }, 500)
  },

  beforeDestroy() {
    this.debouncedWatch.cancel()
  },
})

export const searchOptionMixin = {
  data() {
    return {
      searchText: null,
      abortController: null,
      loading: false,
    }
  },
  methods: {
    async searchKeyword(newVal) {
      if (this.loading) {
        this.abortController?.abort()
      }

      this.loading = true
      try {
        this.abortController = new AbortController()

        const { data } = await orderAPI.getSelectOptionsByTypeAbortable({
          selectType: this.element.type,
          keyword: _.startCase(newVal),
          limit: 30,
          offset: 0,
          signal: this.abortController.signal,
        })

        this.items = data.map((el) => ({ title: el, value: el, selected: false }))
      } catch (error) {
        this.$alerts.error({ message: 'Ошибка загрузки данных', error })
      }

      this.loading = false
      this.abortController = null
    },
  },
  created() {
    this.debouncedWatchSearch = debounce((newValue) => {
      this.searchKeyword(newValue)
    }, 500)

    this.debouncedWatchSearch('')
  },
  watch: {
    searchText(newVal) {
      if (!newVal) {
        return
      }

      this.debouncedWatchSearch(newVal)
    },
  },
}

export const inputRules = () => ({
  computed: {
    rules() {
      const rules = []

      if (this.element.required || (this.element.elements || []).some((el) => el.required)) {
        rules.push(formRules.required)
      }

      return rules
    },
    hasError() {
      if (rules.length > 0) {
        return rules.some((r) => r())
      }
      return false
    },
  },

  mounted() {
    this.$refs?.fieldToValidate?.validate(true)
  },
})
