const components = {
  Type1: 'view-group-one',
  Type2: 'view-group-two',
  Type3: 'view-group-three',
  TypeTransparent: 'view-group-transparent',
  HorizontalInput: 'view-horizontal-input',
  HorizontalInputWithChat: 'view-horizontal-input-with-chat',
}

export const ComponentDetectionMixin = {
  computed: {
    getComponent() {
      return (type) => {
        return components[type]
      }
    },
  },
}
