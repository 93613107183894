import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify.js'
import './assets/styles/main.scss'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import './plugins/interceptors'
import './plugins/user'
import './plugins/alerts'
import './components/form-block'
import './components/view-block'
import './components/view-comments-block'
import './filters/dateFormat'
import './filters/getOfferStatusText'
import './filters/getOrderStatusText'

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
