<template>
  <v-select
    ref="fieldToValidate"
    :items="items"
    :loading="loading"
    v-model="value"
    :disabled="element.disabled"
    item-text="title"
    item-value="value"
    return-object
    no-data-text="Данных нет"
    filled
    :background-color="bgColor"
    :rules="rules"
    validate-on-blur
    class="rounded-lg text-subtitle-2"
    outlined
    dense
    placeholder="Укажите значение"
    clearable
    :append-icon="element.disabled ? 'mdi-lock' : 'mdi-chevron-down'"
    @click:clear="handleChange"
    @change="handleChange"
  />
</template>
<script>
import { inputRules } from '../mixins'

export default {
  name: 'form-select-single',
  inject: ['moduleName'],
  mixins: [inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],

      value: {},
    }
  },

  created() {
    this.getValues()
  },

  methods: {
    async getValues() {
      if (this.loading) {
        return
      }

      this.loading = true
      try {
        const values = this.element.value || []

        this.value = values.find((t) => t.selected)

        this.items = values
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    handleChange(selectedItem) {
      this.updateElement({
        uuid: this.element.uuid,
        key: 'value',
        value: this.items.map((v) => ({ ...v, selected: v.value == selectedItem?.value })),
      })
    },

    updateElement(el) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, el)
    },
  },
}
</script>
