import Vue from 'vue'
import Vuex from 'vuex'
import navigation from './modules/navigation'
import user from './modules/user'
import orderUnderraiter from './modules/order-underraiter'
import alerts from './modules/alerts'
import orderView from './modules/order-view'
import editFormRow from './modules/edit-form-row'
import offer from './modules/offer'
import notes from './modules/notes'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  devtools: process.env.NODE_ENV !== 'production',

  // plugins: [logger],

  modules: {
    navigation,
    user,
    orderUnderraiter,
    alerts,
    orderView,
    offer,
    notes,
    editFormRow,
  },
})
