<template>
  <v-select
    ref="fieldToValidate"
    :items="items"
    :loading="loading"
    v-model="values"
    :disabled="element.disabled"
    item-text="title"
    item-value="value"
    return-object
    filled
    no-data-text="Данных нет"
    :background-color="bgColor"
    class="rounded-lg text-subtitle-2"
    :append-icon="element.disabled ? 'mdi-lock' : 'mdi-chevron-down'"
    outlined
    multiple
    dense
    placeholder="Укажите значение"
    clearable
    :rules="rules"
    validate-on-blur
    @click:clear="handleChange"
    @change="handleChange"
  />
</template>
<script>
import orderAPI from '@/api/orderAPI'
import { inputRules } from '../mixins'

export default {
  name: 'form-select-multiple',
  inject: ['moduleName'],
  mixins: [inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      values: [],
    }
  },

  created() {
    this.getValues()
  },

  methods: {
    async getValues() {
      if (this.loading) {
        return
      }

      try {
        if (this.element.value) {
          if (Array.isArray(this.element.value) && this.element.value.length > 0) {
            const [values] = this.element.value
            this.values = values
          }
        }

        this.loading = true
        const { data } = await orderAPI.getSelectOptionsByType(this.element.type)
        this.items = data.map((el) => ({ title: el, value: el, selected: false }))
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    handleChange(selectedItems) {
      if (!Array.isArray(selectedItems) || selectedItems.length == 0) {
        this.updateElement({ uuid: this.element.uuid, key: 'value', value: null })
        return
      }

      const mappedList = selectedItems.map((item) => {
        return { ...item, selected: true }
      })

      this.updateElement({ uuid: this.element.uuid, key: 'value', value: mappedList })
    },

    updateElement(el) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, el)
    },
  },
}
</script>
