export function findAndAddRecursive({ elems, placeBefore, template }) {
  let index = 0
  for (const el of elems) {
    if (el.uuid == placeBefore) {
      elems.splice(index, 0, template)
      return true
    }

    if (!el.elements) {
      continue
    } else {
      const found = findAndAddRecursive({ elems: el.elements, placeBefore, template })
      if (found) {
        return found
      }
    }

    index++
  }

  return false
}
