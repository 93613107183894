<template>
  <v-dialog v-model="dialog" width="700px">
    <template v-slot:activator="{ attrs, on }">
      <v-btn icon class="mt-1 mr-2" v-bind="attrs" v-on="on"> <v-icon size="19">mdi-pencil</v-icon> </v-btn>
    </template>
    <v-card rounded="lg" color="white" class="pa-6s">
      <v-card-title>Редактировать</v-card-title>
      <v-card-text>
        <component :is="element.type | detectComponent" :element="element" cols="6" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" width="120px" outlined large @click="close()" class="mr-2 rounded-lg">Закрыть</v-btn>
        <v-btn color="primary" :loading="loading" width="120px" large @click="save()" class="rounded-lg">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { detectComponent } from '@/components/form-block/filters'

export default {
  provide: {
    moduleName: 'editFormRow',
  },
  filters: { detectComponent },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },

  watch: {
    dialog(isOpened) {
      if (isOpened) {
        const el = this.findElementById(this.row.id)
        if (el) {
          this.SET_ELEMENT(el)
        }
      }
    },
  },

  methods: {
    ...mapMutations({
      SET_ELEMENT: 'editFormRow/SET_ELEMENT',
    }),
    ...mapActions({ submitElement: 'editFormRow/submitElement', getOrderFormView: 'orderView/getOrderFormView' }),
    close() {
      this.dialog = false
    },
    async save() {
      if (this.loading) return

      this.loading = true

      await this.submitElement()

      this.loading = false
      this.dialog = false

      await this.getOrderFormView(this.$route.params)
    },
  },
  computed: {
    ...mapGetters({
      findElementById: 'orderUnderraiter/findElementById',
      element: 'editFormRow/element',
    }),
  },
}
</script>
