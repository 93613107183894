<template>
  <v-autocomplete
    ref="fieldToValidate"
    v-model="value"
    :items="itemsWithValues"
    :loading="loading"
    :search-input.sync="searchText"
    :background-color="bgColor"
    :disabled="element.disabled"
    :append-icon="element.disabled ? 'mdi-lock' : 'mdi-chevron-down'"
    item-text="title"
    item-value="value"
    outlined
    dense
    filled
    placeholder="Укажите значение"
    no-data-text="Данных нет"
    class="rounded-lg text-subtitle-2"
    clearable
    return-object
    :rules="rules"
    validate-on-blur
    @click:clear="handleChange"
    @change="handleChange"
  >
    <template #prepend-item>
      <div style="position: sticky; top: 0; background: #fff; z-index: 2; margin-top: -20px">
        <v-text-field
          background-color="#fff"
          :disabled="element.disabled"
          prepend-inner-icon="mdi-magnify"
          validate-on-blur
          v-model="searchText"
          filled
          class="ma-3 text-subtitle-2"
          placeholder="Поиск"
          hide-details
        />
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { inputRules, searchOptionMixin } from '../mixins'

export default {
  name: 'form-select-country-single',
  inject: ['moduleName'],
  mixins: [searchOptionMixin, inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],

      value: {},
    }
  },

  created() {
    this.getValues()
  },

  computed: {
    itemsWithValues() {
      if (Object.values(this.value).length == 0) {
        return this.items
      }
      return [this.value, ...this.items]
    },
  },

  methods: {
    getValues() {
      try {
        const values = this.element.value || []

        this.value = values.find((t) => t.selected)

        this.items = values
      } catch (error) {
        console.error(error)
      }
    },

    handleChange(selectedItem) {
      this.updateElement({
        uuid: this.element.uuid,
        key: 'value',
        value: this.items.map((v) => ({ ...v, selected: v.value == selectedItem?.value })),
      })

      if (this.searchText) {
        this.searchText = ''
      }
    },

    updateElement(el) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, el)
    },
  },
}
</script>
