import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import { ROLES } from '../plugins/user'
import { removeToken } from '@/utils/token'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Auth',
    path: '/auth',
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/auth/login/index.vue'),
      },
      {
        name: 'Register',
        path: 'register',
        component: () => import('@/views/auth/register/index.vue'),
        beforeEnter: (to, from, next) => {
          const routeHasLink = 'link' in to.query
          if (!routeHasLink) {
            next('/auth/login')
            return
          }

          next()
        },
      },
      {
        name: 'RegisterInsurer',
        path: 'register-insurer',
        component: () => import('@/views/auth/register-insurer/index.vue'),
      },
      {
        name: 'RecoverPassword',
        path: 'recover-password',
        component: () => import('@/views/auth/recover/index.vue'),
      },
    ],
  },
  {
    path: '/main',
    component: () => import('@/layouts/Main.vue'),
    requiredRoles: [ROLES.agent, ROLES.insurer, ROLES.bigboss, ROLES.statist, ROLES.admin],
    children: [
      {
        name: 'Home',
        path: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.statist, ROLES.admin],
        },
      },
      {
        name: 'BoxProduct',
        path: 'box/:productId',
        component: () => import('@/views/home/ogpo/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.bigboss],
        },
      },
      {
        name: 'UnderraterProduct',
        path: 'underrater/:productId',
        component: () => import('@/views/home/avtokasko/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.bigboss],
        },
      },
      {
        name: 'UnderraterOrderEdit',
        path: '/order/:orderId/edit',
        component: () => import('@/views/home/avtokasko/Edit.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.bigboss],
        },
      },
      {
        name: 'Submissions',
        path: '/submissions',
        component: () => import('@/views/submissions/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.insurer],
        },
      },
      {
        name: 'Applications',
        path: '/applications',
        component: () => import('@/views/applications/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.insurer],
        },
      },
      {
        name: 'OneApplication',
        path: '/applications/:id',
        component: () => import('@/views/applications/OneApplication.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.insurer, ROLES.bigboss, ROLES.agent],
        },
      },
      {
        name: 'Profile',
        path: '/profile',
        component: () => import('@/views/profile/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.insurer, ROLES.bigboss, ROLES.statist, ROLES.admin],
        },
        children: [
          {
            name: 'AgentData',
            path: 'agent-data',
            component: () => import('@/views/profile/components/AgentData.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent],
            },
          },
          {
            name: 'InsurerData',
            path: 'insurer-data',
            component: () => import('@/views/profile/components/InsurerData.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.bigboss, ROLES.statist, ROLES.admin],
            },
          },
          {
            name: 'AccountData',
            path: 'account-data',
            component: () => import('@/views/profile/components/AccountData.vue'),
            meta: {
              check: true,
            },
          },
          {
            name: 'ChangePassword',
            path: 'change-password',
            component: () => import('@/views/profile/components/ChangePassword.vue'),
            meta: {
              check: true,
            },
          },
          {
            path: '/profile/',
            redirect: 'account-data',
          },
        ],
      },
      {
        name: 'Referal',
        path: '/referal',
        component: () => import('@/views/referal/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.bigboss],
        },
      },
      {
        name: 'Bigboss Agent',
        path: '/referal/:id',
        component: () => import('@/views/referal/AgentPage.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.bigboss],
        },
      },
      {
        name: 'Wallet',
        path: '/wallet',
        component: () => import('@/views/wallet/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent],
        },
      },
      {
        name: 'TheNotifications',
        path: '/notifications',
        component: () => import('@/views/notification/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.insurer, ROLES.bigboss, ROLES.statist, ROLES.admin],
        },
      },
      {
        name: 'TheAnalytics',
        path: '/analytics',
        component: () => import('@/views/analytics/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.insurer, ROLES.bigboss, ROLES.admin],
        },
      },
      {
        name: 'OffersAgent',
        path: '/offers-agent/:orderId/',
        component: () => import('@/views/offers-agent/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.statist, ROLES.admin],
        },
        children: [
          {
            name: 'OrderInfoAgent',
            path: 'order-info-agent',
            component: () => import('@/views/offers-agent/pages/OrderInfo/OrderInfo.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            name: 'OffersListAgent',
            path: 'offers-list-agent/:offerId',
            component: () => import('@/views/offers-agent/pages/OffersList/OffersList.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            name: 'OffersNotesAgent',
            path: 'offers-notes-agent/:insurerId',
            component: () => import('@/views/offers-agent/pages/OffersList/OffersNotes.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            name: 'ApplicationFormAgent',
            path: 'application-from-agent/:offerId',
            component: () => import('@/views/offers-agent/pages/ApplicationForm.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            name: 'ApprovalAgent',
            path: 'approval-agent/:offerId',
            component: () => import('@/views/offers-agent/pages/Approval.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            name: 'PaymentAgent',
            path: 'payment-agent/:offerId',
            component: () => import('@/views/offers-agent/pages/ThePaymentComponent.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            name: 'SignOfferAgent',
            path: 'sign-offer-agent/:offerId',
            component: () => import('@/views/offers-agent/pages/TheSignOffer.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
            },
          },
          {
            path: '/offers-agent/',
            redirect: 'order-info-agent',
            requiredRoles: [ROLES.agent, ROLES.bigboss, ROLES.admin],
          },
        ],
      },
      {
        name: 'OffersInsurer',
        path: '/offers-insurer/:orderId/',
        component: () => import('@/views/offers-insurer/index.vue'),
        meta: {
          check: true,
          requiredRoles: [ROLES.insurer, ROLES.statist, ROLES.admin],
        },
        children: [
          {
            name: 'OrderInfoInsurer',
            path: 'order-info-insurer',
            component: () => import('@/views/offers-insurer/pages/OrderInfo.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.admin],
            },
          },
          {
            name: 'ApplicationFormInsurer',
            path: 'application-from-insurer/:offerId',
            component: () => import('@/views/offers-insurer/pages/ApplicationForm.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.admin],
            },
          },
          {
            name: 'ApprovalInsurer',
            path: 'approval-insurer/:offerId',
            component: () => import('@/views/offers-insurer/pages/Approval.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.admin],
            },
          },
          {
            name: 'PaymentInsurer',
            path: 'payment-insurer/:offerId',
            component: () => import('@/views/offers-insurer/pages/ThePaymentComponent.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.admin],
            },
          },
          {
            name: 'SignOfferInsurer',
            path: 'sign-offer-insurer/:offerId',
            component: () => import('@/views/offers-insurer/pages/TheSignOffer.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.admin],
            },
          },
          {
            name: 'ConclusionInsurer',
            path: 'conclusion-insurer',
            component: () => import('@/views/offers-insurer/pages/conclusion/Conclusion.vue'),
            meta: {
              check: true,
              requiredRoles: [ROLES.insurer, ROLES.admin],
            },
            children: [
              {
                name: 'ConclusionInsurerOfferCreate',
                path: 'create',
                component: () => import('@/views/offers-insurer/pages/conclusion/pages/CreateOffer.vue'),
                meta: {
                  check: true,
                  requiredRoles: [ROLES.insurer, ROLES.admin],
                },
              },
              {
                name: 'ConclusionInsurerOfferEdit',
                path: ':offerId/edit',
                component: () => import('@/views/offers-insurer/pages/conclusion/pages/EditableOffer.vue'),
                meta: {
                  check: true,
                  requiredRoles: [ROLES.insurer, ROLES.admin],
                },
              },
              {
                name: 'ConclusionInsurerOfferView',
                path: ':offerId/view',
                component: () => import('@/views/offers-insurer/pages/conclusion/pages/CompleteOffer.vue'),
                meta: {
                  check: true,
                  requiredRoles: [ROLES.insurer, ROLES.admin],
                },
              },
            ],
          },
          {
            path: '/offers-insurer/',
            redirect: 'order-info-insurer',
            requiredRoles: [ROLES.insurer, ROLES.admin],
          },
        ],
      },
    ],
  },
  {
    name: 'OpenOffer',
    path: '/offer',
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        name: 'OpenOfferSign',
        path: 'sign/:offerHash',
        component: () => import('@/views/offers-agent/pages/ThePublicOffer/index.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/main/home',
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const checkAuth = to.matched.some((record) => record.meta.check)

  if (checkAuth) {
    await store.dispatch('user/getUserData')
    const isAuthorized = store.getters['user/isAuthorized']

    if (!isAuthorized) {
      next({ name: 'Login' })
      removeToken()
      return
    }

    const requiredRolesRecords = to.matched.filter(
      ({ meta: { requiredRoles } }) => Array.isArray(requiredRoles) && requiredRoles.length > 0
    )

    const routeRequiresRole = requiredRolesRecords.length > 0

    const checkRole = (r) => requiredRolesRecords.every((rec) => rec.meta.requiredRoles.includes(r))

    const { role } = store.getters['user/data']

    if (routeRequiresRole && !checkRole(role)) {
      if (role == ROLES.insurer) {
        next({ name: 'Submissions' })
      } else {
        next({ name: 'Home' })
      }
      return
    }
  }

  next()
})

export default router
