<template>
  <v-text-field
    ref="fieldToValidate"
    v-model="value"
    v-mask="phoneMask"
    :disabled="element.disabled"
    type="text"
    :background-color="bgColor"
    :append-icon="element.disabled ? 'mdi-lock' : ''"
    filled
    class="rounded-lg text-subtitle-2"
    outlined
    dense
    :rules="rules"
    validate-on-blur
    placeholder="Укажите значение"
  />
</template>
<script>
import { inputRules, updateValueMixin } from '../mixins'

export default {
  name: 'form-phone',
  mixins: [updateValueMixin('value', 'value'), inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phoneMask: '+7 (###) ###-##-##',
      value: '',
    }
  },

  created() {
    if (this.element.value) {
      this.value = this.element.value
    }
  },
}
</script>
