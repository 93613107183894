<template>
  <v-row>
    <v-col>
      <v-sheet color="grey lighten-4 pa-3" rounded="lg">
        <v-container>
          <component
            v-for="row in element.data"
            :key="row.id"
            bg-color="#fff"
            :element="row"
            :is="getComponent(row.type)"
          />
        </v-container>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { ComponentDetectionMixin } from '../mixins'

export default {
  name: 'view-comments-group-two',
  mixins: [ComponentDetectionMixin],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
