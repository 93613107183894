import store from '../store'
import Vue from 'vue'

export const ROLES = {
  agent: 'agent',
  insurer: 'insurer',
  bigboss: 'bigboss',
  statist: 'statist',
  admin: 'admin',
}

const UserPlugin = {
  install(V) {
    V.mixin({
      computed: {
        $user: () => {
          const user = store.getters['user/data']

          return {
            ...user,
            isAgent() {
              return user.role === ROLES.agent
            },
            isInsurer() {
              return user.role === ROLES.insurer
            },
            isBigBoss() {
              return user.role === ROLES.bigboss
            },
            isAdmin() {
              return user.role === ROLES.admin
            },
            isStatist() {
              return user.role === ROLES.statist
            },
          }
        },
      },
    })
  },
}

Vue.use(UserPlugin)
