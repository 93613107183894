<template>
  <v-text-field
    ref="fieldToValidate"
    :background-color="bgColor"
    :disabled="element.disabled"
    :rules="rules"
    validate-on-blur
    v-model="text"
    :append-icon="element.disabled ? 'mdi-lock' : ''"
    filled
    class="rounded-lg text-subtitle-2"
    outlined
    dense
    placeholder="Укажите значение"
    @blur="updateElement({ uuid: element.uuid, key: 'value', value: text })"
  />
</template>
<script>
import { inputRules } from '../mixins'

export default {
  name: 'form-text',
  inject: ['moduleName'],
  mixins: [inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: '',
    }
  },

  methods: {
    updateElement(el) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, el)

      const actions = this.getActionsListByUUID(el.uuid)
      if (Array.isArray(actions)) {
        actions.map((action) => this.$store.dispatch(`${this.moduleName}/executeAction`, action))
      }
    },
  },

  created() {
    if (this.element.value) {
      this.text = this.element.value
    }
  },

  watch: {
    element: {
      handler(newEl) {
        if (newEl.value !== this.text) {
          this.text = newEl.value
        }
      },
      deep: true,
    },
  },

  computed: {
    getActionsListByUUID() {
      return this.$store.getters[`${this.moduleName}/getActionsListByUUID`]
    },
  },
}
</script>
