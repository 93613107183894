import Vue from 'vue'
import orderAPI from '@/api/orderAPI'
import { Alerts } from '@/plugins/alerts'
import router from '@/router'
import { findAndRemoveRecursive } from '@/utils/findAndRemoveRecursive'
import { findAndAddRecursive } from '@/utils/findAndAddRecursive'
import { findRecursive } from '@/utils/findRecursive'
import { checkRequiredFieldsRecursive } from '@/utils/checkRequiredFieldsRecursive'
// import { setRequiredRecursive } from '@/utils/setRequiredRecursive'

const MUTATION_TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_FORM: 'SET_FORM',
  SET_VIEW: 'SET_VIEW',
  SET_FULL_VIEW: 'SET_FULL_VIEW',
  RESET_STATE: 'RESET_STATE',
  TOGGLE_MULTIPLE: 'TOGGLE_MULTIPLE',
  TOGGLE: 'TOGGLE',
  SET_PRESET: 'SET_PRESET',
  LOAD_TEMPLATE: 'LOAD_TEMPLATE',
  ORDER_FORM_SUBMIT: 'ORDER_FORM_SUBMIT',
  UPDATE_ELEMENT: 'UPDATE_ELEMENT',
  REMOVE_ELEMENT: 'REMOVE_ELEMENT',
  SET_REMOVABLE: 'SET_REMOVABLE',
  SET_ORDER: 'SET_ORDER',
  SET_OFFERS: 'SET_OFFERS',
  SET_COMPARISON: 'SET_COMPARISON',
  ADD_REQUIRED_ROW_ID: 'ADD_REQUIRED_ROW_ID',
  REMOVE_REQUIRED_ROW_ID: 'REMOVE_REQUIRED_ROW_ID',
  RESET_REQUIRED_ROWS_IDS: 'RESET_REQUIRED_ROWS',
  CALCULATE_PREMIUM: 'CALCULATE_PREMIUM',
  CALCULATE_PREMIUM_REVERSE: 'CALCULATE_PREMIUM_REVERSE',
  CALCULATE_PREMIUM_REVERSE_PERCENT: 'CALCULATE_PREMIUM_REVERSE_PERCENT',
  CALCULATE_FROM_SUM: 'CALCULATE_FROM_SUM',
  UPDATE_OFFER_STATUS: 'UPDATE_OFFER_STATUS',
}

const state = () => ({
  form: {},
  view: {},
  fullView: {},

  requiredRowsIds: [],

  offers: [],

  comparison: {},

  loading: {},
  removable: {},
})

const mutations = {
  [MUTATION_TYPES.ADD_REQUIRED_ROW_ID](state, { rowId }) {
    state.requiredRowsIds = [...state.requiredRowsIds, rowId]
  },

  [MUTATION_TYPES.REMOVE_REQUIRED_ROW_ID](state, { rowId }) {
    state.requiredRowsIds = state.requiredRowsIds.filter((id) => id != rowId)
  },

  [MUTATION_TYPES.RESET_REQUIRED_ROWS_IDS](state) {
    state.requiredRowsIds = []
  },

  [MUTATION_TYPES.SET_LOADING](state, { key, loading }) {
    Vue.set(state.loading, key, loading)
  },

  [MUTATION_TYPES.SET_FORM](state, form) {
    state.form = {
      ...form,
      // elements: setRequiredRecursive(form.elements, form.required || false),
    }
  },

  [MUTATION_TYPES.CALCULATE_PREMIUM](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }
    const res = (parsedTargetValue * parsedTarget2Value) / 100
    Vue.set(target3, 'value', res.toFixed(2))
    Vue.set(target3, 'disabled', true)
  },

  // CalculateFromSum (target * target2)/100 = target3, (target3 * target4)/100 = target5
  [MUTATION_TYPES.CALCULATE_FROM_SUM](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const target4 = findRecursive(state.form.elements, params.target4)
    const target5 = findRecursive(state.form.elements, params.target5)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }

    const target3Value = (parsedTargetValue * parsedTarget2Value) / 100
    Vue.set(target3, 'value', target3Value.toFixed(2))
    Vue.set(target3, 'disabled', true)

    const parsedTarget4Value = parseFloat(target4.value)

    if (Number.isNaN(parsedTarget4Value)) {
      return
    }

    const target5Value = (target3Value * parsedTarget4Value) / 100

    Vue.set(target5, 'value', target5Value.toFixed(2))
    Vue.set(target5, 'disabled', true)
  },

  [MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }

    const res = (parsedTargetValue * 100) / parsedTarget2Value

    Vue.set(target3, 'value', res.toFixed(2))
    Vue.set(target3, 'disabled', true)
  },

  [MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE_PERCENT](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }

    const res = parsedTarget2Value / (parsedTargetValue / 100)

    Vue.set(target3, 'value', res.toFixed(2))
    Vue.set(target3, 'disabled', true)
  },

  [MUTATION_TYPES.RESET_STATE](s) {
    Object.assign(s, state())
  },

  [MUTATION_TYPES.SET_REMOVABLE](state, { uuid, removable }) {
    state.removable = { ...state.removable, [uuid]: removable }
  },

  [MUTATION_TYPES.SET_PRESET](state, form) {
    state.form = form
  },

  [MUTATION_TYPES.SET_VIEW](state, view) {
    state.view = view
  },

  [MUTATION_TYPES.SET_FULL_VIEW](state, fullView) {
    state.fullView = fullView
  },

  [MUTATION_TYPES.SET_OFFERS](state, offers) {
    state.offers = offers
  },

  [MUTATION_TYPES.SET_COMPARISON](state, comparison) {
    state.comparison = comparison
  },

  [MUTATION_TYPES.LOAD_TEMPLATE](state, { template, placeBefore, actions }) {
    state.form.actions = [...state.form.actions, ...actions]
    findAndAddRecursive({ elems: state.form.elements, placeBefore, template })
  },

  [MUTATION_TYPES.REMOVE_ELEMENT](state, { uuid }) {
    findAndRemoveRecursive({ elems: state.form.elements, uuid })
  },

  [MUTATION_TYPES.TOGGLE](state, { show, hide }) {
    if (show) {
      const elem = findRecursive(state.form.elements || [], show)

      if (elem) {
        Vue.set(elem, 'is_hidden', false)
      }
    }

    if (hide) {
      const elem = findRecursive(state.form.elements || [], hide)

      if (elem) {
        Vue.set(elem, 'is_hidden', true)
      }
    }
  },

  [MUTATION_TYPES.TOGGLE_MULTIPLE](state, { show, hide }) {
    if (Array.isArray(show)) {
      for (const uuid of show) {
        const elem = findRecursive(state.form.elements || [], uuid)

        if (elem) {
          Vue.set(elem, 'is_hidden', false)
        }
      }
    }

    if (Array.isArray(hide)) {
      for (const uuid of hide) {
        const elem = findRecursive(state.form.elements || [], uuid)

        if (elem) {
          Vue.set(elem, 'is_hidden', true)
        }
      }
    }
  },

  [MUTATION_TYPES.UPDATE_ELEMENT](state, { uuid, key, value }) {
    const elem = findRecursive(state.form.elements || [], uuid)

    if (elem) {
      Vue.set(elem, key, value)
    }
  },
  [MUTATION_TYPES.UPDATE_OFFER_STATUS](state, { id, status }) {
    for (const offer of state.offers) {
      if (offer.id == id) {
        Vue.set(offer, 'status', status)
        break
      }
    }
  },
}

const actions = {
  async getForm({ commit, rootState }, { offerId }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'form', loading: true })

    try {
      const { view } = rootState.orderView
      const { data } = await orderAPI.getOfferForm(view.id, offerId)

      commit(MUTATION_TYPES.SET_FORM, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки формы предложения', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'form', loading: false })
  },

  async getPreset({ commit, rootState }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'form', loading: true })

    try {
      const { view } = rootState.orderView
      const { data } = await orderAPI.getOfferPresetForForm(view.id)

      commit(MUTATION_TYPES.SET_FORM, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки шаблона формы предложения', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'form', loading: false })
  },

  async getView({ commit }, { formId, offerId }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'view', loading: true })

    try {
      const { data } = await orderAPI.getOfferView(formId, offerId)

      commit(MUTATION_TYPES.SET_VIEW, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки данных предложения', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'view', loading: false })
  },

  async getFullView({ commit }, { offerId, hideEmpty = false }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'fullView', loading: true })

    try {
      const { data } = await orderAPI.getRequiredOfferView(offerId, hideEmpty)

      commit(MUTATION_TYPES.SET_FULL_VIEW, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки данных предложения', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'fullView', loading: false })
  },

  async getOffers({ commit, rootState }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'offers', loading: true })

    try {
      const { view } = rootState.orderView
      const { data } = await orderAPI.getAllOffersForm(view.id)

      const { user } = rootState.user
      if (user.role == 'insurer') {
        commit(
          MUTATION_TYPES.SET_OFFERS,
          data.filter(({ user_id }) => user_id == user.id) // берем только свои офферы
        )
      } else {
        commit(MUTATION_TYPES.SET_OFFERS, data)
      }
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки данных предложения', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'offers', loading: false })
  },

  async getComparison({ commit, rootState, dispatch }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'comparison', loading: true })

    try {
      const { orderId } = router.currentRoute.params
      const orderViewResponse = await orderAPI.getOrderFormView(orderId)
      const formId = orderViewResponse.data.id

      const { offerId } = router.currentRoute.params
      await dispatch('getView', { formId, offerId })

      const { user } = rootState.user

      const { data } = await orderAPI.getComparedForms(formId, offerView.id, user.id)

      commit(MUTATION_TYPES.SET_COMPARISON, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки сравнения форм', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'comparison', loading: false })
  },

  async loadTemplate({ commit }, { templateType, placeBefore, target }) {
    commit(MUTATION_TYPES.SET_LOADING, { uuid: target, loading: true })

    try {
      const { data } = await orderAPI.loadTemplate({ templateType })

      commit(MUTATION_TYPES.LOAD_TEMPLATE, { template: data.template, actions: data.actions, placeBefore })
      commit(MUTATION_TYPES.SET_REMOVABLE, { uuid: data.template.uuid, removable: true })
    } catch (error) {
      Alerts.error({ message: 'Ошибка при создании заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid: target, loading: false })
  },

  async offerFormSubmit({ state, rootState, commit }, { uuid }) {
    const invalidFields = checkRequiredFieldsRecursive({ elems: state.form.elements })
    if (invalidFields.length > 0) {
      Alerts.error({ message: 'Заполните все обязательные поля' })
      return
    }

    const { user } = rootState.user
    const { view } = rootState.orderView
    commit(MUTATION_TYPES.SET_LOADING, { uuid, loading: true })

    try {
      const { id, order_id } = state.form
      if (id & order_id) {
        await orderAPI.updateOffer(order_id, {
          form_id: id,
          user_id: user.id,
          status: orderAPI.OFFER_STATUSES.UNDER_REVIEW,
        })

        commit(MUTATION_TYPES.RESET_STATE)

        Alerts.success({ message: 'Предложение успешно обновлено!' })

        await router.push({ name: 'ConclusionInsurerOfferView', params: { offerId: id } })
      } else {
        const { data } = await orderAPI.createOffer({ formId: view.id, userId: user.id, offer: state.form })

        commit(MUTATION_TYPES.RESET_STATE)

        Alerts.success({ message: 'Предложение успешно создано! Номер предложения: ' + data.id })

        await router.push({ name: 'ConclusionInsurerOfferView', params: { offerId: data.id } })
      }
    } catch (error) {
      Alerts.error({ message: 'Ошибка при создании предложения', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid, loading: false })
  },

  executeAction({ commit, dispatch }, { type, params, target }) {
    if (type == 'LoadTemplate') {
      dispatch('loadTemplate', { ...params, target })
    } else if (type == 'ToggleMultiple') {
      commit(MUTATION_TYPES.TOGGLE_MULTIPLE, params)
    } else if (type == 'Toggle') {
      commit(MUTATION_TYPES.TOGGLE, params)
    } else if (type == 'CalculateFromSum') {
      commit(MUTATION_TYPES.CALCULATE_FROM_SUM, { params, target })
    } else if (type == 'CalculatePremium') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM, { params, target })
    } else if (type == 'CalculateMotivation') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM, { params, target })
    } else if (type == 'CalculatePremiumReverse') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE, { params, target })
    } else if (type == 'CalculatePremiumReversePercent') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE_PERCENT, { params, target })
    } else if (type == 'OfferFormSubmit') {
      dispatch('offerFormSubmit', { uuid: target })
    }
  },
}

const getters = {
  form({ form }) {
    return form
  },

  fullView({ fullView }) {
    return fullView
  },

  requiredRowsIds({ requiredRowsIds }) {
    return requiredRowsIds
  },

  view({ view }) {
    return view
  },

  offers({ offers }) {
    return offers
  },

  acceptedOffer({ offers }) {
    return offers.find((offer) => offer.status == orderAPI.OFFER_STATUSES.ACCEPTED)
  },

  lastOfferId({ offers }) {
    return offers.reduce((prevId, curr) => (curr.id > prevId ? curr.id : prevId), 0)
  },

  lastOffer({ offers }) {
    return offers.reduce((prev, curr) => (curr.id > prev?.id ? curr : prev), null)
  },

  comparison({ comparison }) {
    return comparison
  },

  loading({ loading }) {
    return loading
  },

  getLoadingFor({ loading }) {
    return (key) => loading[key]
  },

  getJoinedOrderAndOffer({ view, rootGetters }) {
    return { ...view, ...rootGetters }
  },

  getActionsListByUUID({ form: { actions } }) {
    return (uuid) => actions.filter(({ target }) => target == uuid)
  },

  getActionByUUID({ form: { actions } }) {
    return (uuid) => actions.find(({ target }) => target == uuid)
  },

  getLoadingByUUID({ loading }) {
    return (uuid) => loading[uuid]
  },

  isRemovable({ removable }) {
    return (uuid) => removable[uuid]
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
