import orderAPI, { ORDER_STATUSES } from '@/api/orderAPI'
import productAPI from '@/api/productAPI'
import { Alerts } from '@/plugins/alerts'
import { checkRequiredFieldsRecursive } from '@/utils/checkRequiredFieldsRecursive'
import { findAndAddRecursive } from '@/utils/findAndAddRecursive'
import { findAndRemoveRecursive } from '@/utils/findAndRemoveRecursive'
import { findRecursive } from '@/utils/findRecursive'
import { findRecursiveById } from '@/utils/findRecursiveById'
import Vue from 'vue'
import router from '../../router'

const MUTATION_TYPES = {
  SET_PRESET: 'SET_PRESET',
  TOGGLE: 'TOGGLE',
  TOGGLE_MULTIPLE: 'TOGGLE_MULTIPLE',
  LOAD_TEMPLATE: 'LOAD_TEMPLATE',
  ORDER_FORM_SUBMIT: 'ORDER_FORM_SUBMIT',
  UPDATE_ELEMENT: 'UPDATE_ELEMENT',
  REMOVE_ELEMENT: 'REMOVE_ELEMENT',
  SET_LOADING: 'SET_LOADING',
  SET_REMOVABLE: 'SET_REMOVABLE',
  RESET_STATE: 'RESET_STATE',
  SET_ORDER: 'SET_ORDER',
  CALCULATE_PREMIUM: 'CALCULATE_PREMIUM',
  CALCULATE_PREMIUM_REVERSE: 'CALCULATE_PREMIUM_REVERSE',
  CALCULATE_PREMIUM_REVERSE_PERCENT: 'CALCULATE_PREMIUM_REVERSE_PERCENT',
  CALCULATE_FROM_SUM: 'CALCULATE_FROM_SUM',
}

const state = () => ({
  form: {
    id: 0,
    uuid: '',
    order_id: 0,
    created_at: '',
    updated_at: '',
    actions: [],
    elements: [],
  },

  order: {
    id: 0,
    product_id: 0,
    user_id: 0,
    product_type: 'underrater',
    accident_count: 0,
    fix_sum: null,
    email: null,
    phone: null,
    deleted_at: null,
    created_at: '',
    updated_at: '',
    key: '',
    status_id: 3,
    status: '',
    ogpo: null,
    prices: [],
  },

  loading: {},
  removable: {},
})

const mutations = {
  [MUTATION_TYPES.RESET_STATE](s) {
    Object.assign(s, state())
  },

  [MUTATION_TYPES.SET_LOADING](state, { uuid, loading }) {
    state.loading = { ...state.loading, [uuid]: loading }
  },

  [MUTATION_TYPES.SET_REMOVABLE](state, { uuid, removable }) {
    state.removable = { ...state.removable, [uuid]: removable }
  },

  [MUTATION_TYPES.SET_PRESET](state, form) {
    state.form = {
      ...form,
    }
  },

  [MUTATION_TYPES.LOAD_TEMPLATE](state, { template, placeBefore, actions }) {
    state.form.actions = [...state.form.actions, ...actions]
    const found = findAndAddRecursive({ elems: state.form.elements, placeBefore, template })
    if (!found) {
      Alerts.error({ message: 'Елемент не найден' })
    }
  },

  [MUTATION_TYPES.REMOVE_ELEMENT](state, { uuid }) {
    findAndRemoveRecursive({ elems: state.form.elements, uuid })
  },

  [MUTATION_TYPES.TOGGLE](state, { show, hide }) {
    if (show) {
      const elem = findRecursive(state.form.elements || [], show)

      if (elem) {
        Vue.set(elem, 'is_hidden', false)
      }
    }

    if (hide) {
      const elem = findRecursive(state.form.elements || [], hide)

      if (elem) {
        Vue.set(elem, 'is_hidden', true)
      }
    }
  },

  [MUTATION_TYPES.TOGGLE_MULTIPLE](state, { show, hide }) {
    if (Array.isArray(show)) {
      for (const uuid of show) {
        const elem = findRecursive(state.form.elements || [], uuid)

        if (elem) {
          Vue.set(elem, 'is_hidden', false)
        }
      }
    }

    if (Array.isArray(hide)) {
      for (const uuid of hide) {
        const elem = findRecursive(state.form.elements || [], uuid)

        if (elem) {
          Vue.set(elem, 'is_hidden', true)
        }
      }
    }
  },

  [MUTATION_TYPES.UPDATE_ELEMENT](state, { uuid, key, value }) {
    const elem = findRecursive(state.form.elements || [], uuid)

    if (elem) {
      Vue.set(elem, key, value)
    }
  },

  [MUTATION_TYPES.SET_ORDER](state, order) {
    state.order = order
  },

  [MUTATION_TYPES.CALCULATE_PREMIUM](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }
    const res = (parsedTargetValue * parsedTarget2Value) / 100
    Vue.set(target3, 'value', res.toFixed(2))
    Vue.set(target3, 'disabled', true)
  },

  // CalculateFromSum (target * target2)/100 = target3, (target3 * target4)/100 = target5
  [MUTATION_TYPES.CALCULATE_FROM_SUM](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const target4 = findRecursive(state.form.elements, params.target4)
    const target5 = findRecursive(state.form.elements, params.target5)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }

    const target3Value = (parsedTargetValue * parsedTarget2Value) / 100
    Vue.set(target3, 'value', target3Value.toFixed(2))
    Vue.set(target3, 'disabled', true)

    const parsedTarget4Value = parseFloat(target4.value)

    if (Number.isNaN(parsedTarget4Value)) {
      return
    }

    const target5Value = (target3Value * parsedTarget4Value) / 100

    Vue.set(target5, 'value', target5Value.toFixed(2))
    Vue.set(target5, 'disabled', true)
  },

  [MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }

    const res = (parsedTargetValue * 100) / parsedTarget2Value

    Vue.set(target3, 'value', res.toFixed(2))
    Vue.set(target3, 'disabled', true)
  },

  [MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE_PERCENT](state, { params, target }) {
    const target2 = findRecursive(state.form.elements, params.target2)
    const target3 = findRecursive(state.form.elements, params.target3)
    const targetElem = findRecursive(state.form.elements, target)

    const parsedTargetValue = parseFloat(targetElem.value)
    if (Number.isNaN(parsedTargetValue)) {
      return
    }

    const parsedTarget2Value = parseFloat(target2.value)

    if (Number.isNaN(parsedTarget2Value)) {
      return
    }

    const res = parsedTarget2Value / (parsedTargetValue / 100)

    Vue.set(target3, 'value', res.toFixed(2))
    Vue.set(target3, 'disabled', true)
  },
}

const actions = {
  async getPreset({ commit }, presetType) {
    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'main', loading: true })
    try {
      const preset = await orderAPI.getPreset(presetType)

      commit(MUTATION_TYPES.SET_PRESET, preset)
    } catch (error) {
      Alerts.error({ message: 'Ошибка при загрузки шаблона', error })
    }
    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'main', loading: false })
  },

  async getForm({ commit }, orderId) {
    try {
      commit(MUTATION_TYPES.SET_LOADING, { uuid: 'main', loading: true })
      const { data } = await orderAPI.getOrderForm(orderId)

      commit(MUTATION_TYPES.SET_PRESET, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка при загрузки формы заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'main', loading: false })
  },

  async getRequiredOfferForm({ commit }, offerId) {
    try {
      commit(MUTATION_TYPES.SET_LOADING, { uuid: 'main', loading: true })

      const { data } = await orderAPI.getRequiredOfferForm(offerId)

      commit(MUTATION_TYPES.SET_PRESET, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка при загрузки формы заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'main', loading: false })
  },

  async getOrder({ commit }, order_id) {
    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'order', loading: true })

    try {
      const { data } = await orderAPI.getOneOrderById(order_id)

      commit(MUTATION_TYPES.SET_ORDER, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка при загрузки заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'order', loading: false })
  },

  async loadTemplate({ commit }, { templateType, placeBefore, target }) {
    commit(MUTATION_TYPES.SET_LOADING, { uuid: target, loading: true })

    try {
      const { data } = await orderAPI.loadTemplate({ templateType })

      commit(MUTATION_TYPES.LOAD_TEMPLATE, { template: data.template, actions: data.actions, placeBefore })
      commit(MUTATION_TYPES.SET_REMOVABLE, { uuid: data.template.uuid, removable: true })
    } catch (error) {
      Alerts.error({ message: 'Ошибка при создании заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid: target, loading: false })
  },

  async orderFormSubmit({ state, rootState, commit }, { uuid }) {
    const invalidFields = checkRequiredFieldsRecursive({ elems: state.form.elements })
    if (invalidFields.length > 0) {
      Alerts.error({ message: 'Заполните все обязательные поля' })
      return
    }

    const { user } = rootState.user
    commit(MUTATION_TYPES.SET_LOADING, { uuid, loading: true })

    try {
      const { id, order_id } = state.form
      if (id & order_id) {
        await orderAPI.updateForm(id, { ...state.form, order_id: order_id })

        commit(MUTATION_TYPES.RESET_STATE)

        Alerts.success({ message: 'Заявка успешно обновлена!' })

        await router.push({ name: 'OrderInfoAgent', params: { orderId: order_id } })
      } else {
        const { productId } = router.currentRoute.params
        const product = await productAPI.getPrductByProductId(productId)
        const { data } = await orderAPI.createOrder({
          key: product.data.key,
          user_id: user.id,
          product_id: productId,
          product_type: product.data.type,
          status_id: ORDER_STATUSES.UNDER_REVIEW,
        })

        await orderAPI.createForm({ ...state.form, order_id: data.data.order_id })
        commit(MUTATION_TYPES.RESET_STATE)

        Alerts.success({ message: 'Заявка успешно создана! Номер заявки: ' + data.data.order_id })

        await router.push({ name: 'OrderInfoAgent', params: { orderId: data.data.order_id } })
      }
    } catch (error) {
      Alerts.error({ message: 'Ошибка при создании заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid, loading: false })
  },

  executeAction({ commit, dispatch }, { type, params, target }) {
    if (type == 'LoadTemplate') {
      dispatch('loadTemplate', { ...params, target })
    } else if (type == 'ToggleMultiple') {
      commit(MUTATION_TYPES.TOGGLE_MULTIPLE, params)
    } else if (type == 'Toggle') {
      commit(MUTATION_TYPES.TOGGLE, params)
    } else if (type == 'CalculateFromSum') {
      commit(MUTATION_TYPES.CALCULATE_FROM_SUM, { params, target })
    } else if (type == 'CalculatePremium') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM, { params, target })
    } else if (type == 'CalculateMotivation') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM, { params, target })
    } else if (type == 'CalculatePremiumReverse') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE, { params, target })
    } else if (type == 'CalculatePremiumReversePercent') {
      commit(MUTATION_TYPES.CALCULATE_PREMIUM_REVERSE_PERCENT, { params, target })
    } else if (type == 'OrderFormSubmit') {
      dispatch('orderFormSubmit', { uuid: target })
    }
  },
}

const getters = {
  getActionsListByUUID({ form: { actions } }) {
    return (uuid) =>
      actions.filter(({ target }) => {
        if (Array.isArray(target)) {
          return target.includes(uuid)
        }

        return target == uuid
      })
  },
  getActionByUUID({ form: { actions } }) {
    return (uuid) =>
      actions.find(({ target }) => {
        if (Array.isArray(target)) {
          return target.includes(uuid)
        }

        return target == uuid
      })
  },

  getLoadingByUUID({ loading }) {
    return (uuid) => loading[uuid]
  },

  isRemovable({ removable }) {
    return (uuid) => removable[uuid]
  },

  preset({ form }) {
    return form
  },

  loading({ loading }) {
    return loading
  },

  order({ order }) {
    return order
  },

  findElementById({ form }) {
    return (id) => {
      return findRecursiveById(form.elements, id)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
