<template>
  <v-row v-if="!element.is_hidden">
    <v-col cols="4">
      <label class="label text-subtitle-2 font-weight-bold">
        {{ element.name }} <span v-show="isRequiredRow" class="text text-red">*</span>
      </label>
    </v-col>
    <v-col>
      <form-checkbox v-for="el in element.elements" :key="el.uuid" :element="el" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-row-vertical-input',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isRequiredRow() {
      return this.element.required || this.element.elements?.some((el) => el.required)
    },
  },
}
</script>
