<template>
  <v-row v-if="!element.is_hidden">
    <v-col :cols="cols">
      <v-btn
        v-for="el in element.elements"
        :key="el.uuid"
        class="rounded-lg"
        large
        color="primary"
        :disabled="loading || !getActionByUUID(el.uuid) || getLoadingByUUID(el.uuid)"
        :loading="loading || getLoadingByUUID(el.uuid)"
        @click="
          submit(el)
          loading = true
        "
      >
        {{ el.title }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-row-button-container',

  inject: ['moduleName'],

  props: {
    cols: {
      type: String,
      default: '4',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async submit(el) {
      if (this.getLoadingByUUID(el.uuid) || this.loading) {
        return
      }

      const action = this.getActionByUUID(el.uuid)
      if (action) {
        await this.$store.dispatch(`${this.moduleName}/executeAction`, action)
      }

      this.loading = false
    },
  },
  computed: {
    getActionByUUID() {
      return this.$store.getters[`${this.moduleName}/getActionByUUID`]
    },

    getLoadingByUUID() {
      return this.$store.getters[`${this.moduleName}/getLoadingByUUID`]
    },
  },
}
</script>
