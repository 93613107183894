<template>
  <v-container v-if="!element.is_hidden" class="group-transparent">
    <component
      v-for="row in element.elements"
      :key="row.uuid"
      :cols="cols"
      :element="row"
      :is="row.type | detectComponent"
    />
  </v-container>
</template>
<script>
import { detectComponent } from '../filters'

export default {
  name: 'form-group-transparent',
  props: {
    cols: {
      type: String,
      default: '4',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  filters: {
    detectComponent,
  },
  data() {
    return {}
  },
}
</script>
