<template>
  <v-row>
    <v-col>
      <v-sheet rounded="lg" class="pa-6 rounded-b-0 order-info__zebra">
        <v-container fluid>
          <v-row v-if="element.header">
            <v-col>
              <h6 class="text-h6 font-weight-bold mb-4">{{ element.header }}</h6>
              <v-divider />
            </v-col>
          </v-row>

          <component v-for="el in element.data" :key="el.id" :element="el" :is="getComponent(el.type)" />
        </v-container>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { ComponentDetectionMixin } from '../mixins'

export default {
  name: 'view-group-one',
  mixins: [ComponentDetectionMixin],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
