import store from '@/store'
import Vue from 'vue'

export const Alerts = {
  success({ message }) {
    store.commit('alerts/ADD_ONE', { message, type: 'success' })
  },
  info({ message }) {
    store.commit('alerts/ADD_ONE', { message, type: 'info' })
  },
  warning({ message }) {
    store.commit('alerts/ADD_ONE', { message, type: 'warning' })
  },
  error({ message, error }) {
    store.commit('alerts/ADD_ONE', { message, type: 'error' })
    if (error && process.env.NODE_ENV === 'development') {
      console.error(error)
    }
  },
}

export const AlertPlugin = {
  install(V) {
    V.prototype.$alerts = Alerts
  },
}

Vue.use(AlertPlugin)
