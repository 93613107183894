<template>
  <v-row v-if="!element.is_hidden">
    <v-col>
      <v-sheet rounded="lg" class="pa-6 rounded-b-0">
        <v-container fluid>
          <v-row v-if="element.header">
            <v-col>
              <h6 class="text-h6 font-weight-bold mb-4">{{ element.header }}</h6>
              <v-divider />
            </v-col>
          </v-row>

          <v-row v-for="alert in element.alerts" :key="alert.id">
            <v-sheet rounded="lg" class="pa-6 mb-2 alert-block" :class="getClassesForAlerts(alert.type)" width="100%">
              <div class="flex-grow-1">
                <p class="alert-block__title">{{ alert.title }}</p>
                <p class="alert-block__description">
                  {{ alert.description }}
                </p>
              </div>
              <v-btn
                v-for="button in alert.buttons"
                :key="button.id"
                width="170px"
                color="grey"
                class="lighten-2 rounded-lg mr-2 text-grey1"
                elevation="0"
                @click="$store.dispatch(button.action)"
              >
                {{ button.title }}
              </v-btn>
            </v-sheet>
          </v-row>

          <component v-for="el in element.elements" :key="el.uuid" :element="el" :is="el.type | detectComponent" />
        </v-container>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { detectComponent } from '../filters'

export default {
  name: 'form-group-one',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  filters: {
    detectComponent,
  },
  data() {
    return {}
  },
}
</script>
