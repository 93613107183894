<template>
  <v-btn
    class="rounded-lg mb-4"
    color="primary"
    :disabled="!getActionByUUID(element.uuid)"
    :loading="getLoadingByUUID(element.uuid)"
    @click="submit(element)"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: 'form-add-file-button',
  inject: ['moduleName'],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    submit(el) {
      const action = this.getActionByUUID(el.uuid)
      if (action) {
        this.$store.dispatch(`${this.moduleName}/executeAction`, action)
      }
    },
  },
  computed: {
    getActionByUUID() {
      return this.$store.getters[`${this.moduleName}/getActionByUUID`]
    },

    getLoadingByUUID() {
      return this.$store.getters[`${this.moduleName}/getLoadingByUUID`]
    },
  },
}
</script>
