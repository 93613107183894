<template>
  <v-text-field
    ref="fieldToValidate"
    v-model="idNumber"
    :disabled="element.disabled"
    background-color="#F3F6F8"
    filled
    class="rounded-lg text-subtitle-2"
    outlined
    dense
    :rules="rules"
    validate-on-blur
    placeholder="Укажите значение"
    :append-icon="element.disabled ? 'mdi-lock' : ''"
  />
</template>
<script>
import { inputRules, updateValueMixin } from '../mixins'

export default {
  name: 'form-id-number',
  mixins: [updateValueMixin('idNumber', 'value'), inputRules()],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      idNumber: '',
    }
  },

  created() {
    if (this.element.value) {
      this.idNumber = this.element.value
    }
  },
}
</script>
