import axios from 'axios'
import { getToken, removeToken } from '@/utils/token'

const handleAxiosError = (error) => {
  if (Array.isArray(error.response?.data?.error)) {
    return Promise.reject(new Error((error.response?.data?.error || ['Неизвестная ошибка']).join(', ')))
  }

  if (typeof error.response?.data?.error == 'object') {
    const errorMessages = Object.values(error.response?.data?.error).reduce((prev, curr) => {
      return [...prev, curr]
    }, [])
    const err = new Error((errorMessages || ['Неизвестная ошибка']).join(', '))
    err.response = error.response

    return Promise.reject(err)
  }

  const err = new Error(error.response?.data?.error || 'Неизвестная ошибка')
  err.response = error.response
  return Promise.reject(err)
}

axios.interceptors.request.use(function (config) {
  const token = getToken()
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    if (err.response.status == 401) {
      removeToken()
    }

    return handleAxiosError(err)
  }
)
