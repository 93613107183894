function getToken() {
  const token = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : sessionStorage.getItem('token')
    ? sessionStorage.getItem('token')
    : null

  return token
}

async function setToken(token, remember) {
  if (remember) {
    localStorage.setItem('token', token)
  } else {
    sessionStorage.setItem('token', token)
  }
}

function removeToken() {
  localStorage.removeItem('token')
  sessionStorage.removeItem('token')
}

export { getToken, setToken, removeToken }
