<template>
  <v-row class="mb-3">
    <v-col cols="4" class="mr-3" :class="{ 'font-weight-bold': element.is_bold_name }"> {{ element.name }} </v-col>
    <v-checkbox v-if="showCheckboxes" v-model="isChecked" hide-details class="mt-2 mr-1" />

    <template v-if="isItLink">
      <v-btn
        v-for="file in files"
        :key="file.path"
        :loading="loadings[file.path]"
        elevation="0"
        text
        color="primary"
        class="text-capitalize text-teal"
        @click="downloadFile(file.path)"
      >
        {{ file.fileName || 'Document' }} <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <ChatDropdown :row="element" v-if="showComments">
        <v-col>{{ flattedData }} </v-col>
      </ChatDropdown>

      <v-col v-else> {{ flattedData }} </v-col>
    </template>
  </v-row>
</template>
<script>
import ChatDropdown from '@/views/offers-insurer/components/ChatDropdown.vue'
import { mapGetters, mapMutations } from 'vuex'
import { downloadFileByLink } from '@/api/fileAPI'
import path from 'path'
export default {
  name: 'view-horizontal-input',
  inject: ['showCheckboxes', 'showComments'],
  components: { ChatDropdown },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isChecked: false,
      loading: false,
      loadings: {},
    }
  },

  mounted() {
    this.isChecked = this.flattedData.length > 0
  },

  computed: {
    ...mapGetters({
      view: 'orderView/view',
    }),
    flattedData() {
      const { data } = this.element

      if (Array.isArray(data)) {
        if (data.length == 1) {
          const [probablyJson] = data

          if (Array.isArray(probablyJson)) {
            return (probablyJson || []).flat().join(', ')
          }

          try {
            const values = JSON.parse(probablyJson)

            return values.reduce((prev, curr) => {
              if (curr.selected) {
                if (!prev?.length) {
                  return curr.title
                }

                return prev + ', ' + curr.title
              }

              return prev
            }, '')
          } catch (error) {
            return probablyJson
          }
        }
      }
      return (data || []).flat().join(', ')
    },

    isItLink() {
      return this.flattedData.split(', ').every((c) => c.startsWith('20') && c.includes('/'))
    },

    fileName() {
      if (!this.isItLink) {
        return ''
      }

      return this.flattedData.split('/').pop()
    },

    files() {
      if (!this.isItLink) {
        return []
      }

      return this.flattedData.split(', ').map((p) => {
        const fName = path.parse(p)
        return {
          fileName: fName.base,
          path: p,
        }
      })
    },
  },

  watch: {
    isChecked: {
      handler(val) {
        if (val) {
          this.addRowIdToRequiredIds({ rowId: this.element.id })
        } else {
          this.removeRowIdFromRequiredIds({ rowId: this.element.id })
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      addRowIdToRequiredIds: 'offer/ADD_REQUIRED_ROW_ID',
      removeRowIdFromRequiredIds: 'offer/REMOVE_REQUIRED_ROW_ID',
    }),

    async downloadFile(link) {
      if (!this.isItLink) {
        return
      }

      if (this.loadings[link]) {
        return
      }

      this.$set(this.loadings, link, true)

      try {
        await downloadFileByLink(link)
      } catch (error) {
        this.$alerts.error({ message: 'Не удалось скачать файл', error })
      }

      this.$set(this.loadings, link, false)
    },
  },
}
</script>
