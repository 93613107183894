export const handleError = ({ success, errors }) => {
  if (typeof success == 'boolean' && !success) {
    if (Array.isArray(errors)) {
      throw new Error((errors || ['Неизвестная ошибка']).join(', '))
    }

    if (typeof errors == 'object') {
      const errorMessages = Object.values(errors).reduce((prev, curr) => {
        return [...prev, curr]
      }, [])

      throw new Error((errorMessages || ['Неизвестная ошибка']).join(', '))
    }

    throw new Error(errors)
  }
}
