import { handleError } from '@/utils/handleError'
import axios from 'axios'
import store from '../store'
import { baselURL, orderURL } from './baseURL'

/**
 * "0": "На рассмотрении",
 * "1": "Отменен",
 * "2": "Отказано",
 * "3": "Подвержден",
 * "4": "В архиве",
 *
 * */
export const OFFER_STATUSES = {
  UNDER_REVIEW: 0,
  CANCELLED: 1,
  DENIED: 2,
  ACCEPTED: 3,
  ARCHIVE: 4,
}

/**
 * "1": "Ожидает оплаты",
 * "2": "Оплачено",
 * "3": "Черновик",
 * "4": "Отменен",
 * "5": "На рассмотрении",
 * "6": "Заявление-анкета",
 * "7": "На утверждении",
 * "8": "На подписании",
 * "9": "Ожидает оплаты (рассрочка)",
 * "10": "Оплачено (рассрочка)"
 *
 */
export const ORDER_STATUSES = {
  WAITING_FOR_PAYMENT: 1,
  PAID: 2,
  DRAFT: 3,
  CANCELLED: 4,
  UNDER_REVIEW: 5,
  APPLICATION_FORM: 6,
  ON_APPROVAL: 7,
  ON_SIGNING: 8,
  WAITING_FOR_PAYMENT_INSTALLMENTS: 9,
  PAID_INSTALLMENTS: 10,
  APPLICATION_FORM_PICK_FIELDS: 11,
  APPLICATION_FORM_FILL_FORM: 12,
}

export default {
  OFFER_STATUSES,
  ORDER_STATUSES,

  createOrder(data) {
    return axios.post(`${orderURL}/order`, data)
  },

  async getOrders(
    {
      page = 1,
      per_page = 10,
      sort_price,
      product_type,
      policyholder,
      policyholder_identifier,
      product_keys,
      status_ids,
      from_date,
      until_date,
      search,
    } = { page: 1, per_page: 10 }
  ) {
    const { id } = store.getters['user/data']

    if (!id) {
      throw new Error('User id is invalid')
    }

    const { data } = await axios.get(`${orderURL}/order`, {
      params: {
        page,
        per_page,
        user_id: id,
        sort_price,
        product_type,
        policyholder,
        policyholder_identifier,
        product_keys,
        status_ids,
        from_date,
        until_date,
        search,
      },
    })
    return data
  },

  async getInsurerOrders(
    {
      page = 1,
      per_page = 10,
      sort_price,
      product_type,
      policyholder,
      policyholder_identifier,
      product_keys,
      status_ids,
      from_date,
      until_date,
      search,
    } = { page: 1, per_page: 10 }
  ) {
    const { id } = store.getters['user/data']

    if (!id) {
      throw new Error('User id is invalid')
    }

    const { data } = await axios.get(`${orderURL}/insurer/orders`, {
      params: {
        page,
        per_page,
        user_id: id,
        sort_price,
        product_type,
        policyholder,
        policyholder_identifier,
        product_keys,
        status_ids,
        from_date,
        until_date,
        search,
      },
    })
    return data
  },

  async getOneOrderById(orderId) {
    const { data } = await axios.get(`${orderURL}/order/show/${orderId}`)
    handleError(data)
    return data
  },

  async updateOrder(orderId, order) {
    const { data } = await axios.post(`${orderURL}/order/${orderId}`, order)
    handleError(data)
    return data
  },

  async updateOrderStatus(orderId, statusId) {
    const { data } = await axios.post(`${orderURL}/order/${orderId}/status`, { status_id: statusId })
    handleError(data)
    return data
  },

  async getUnderriter({
    page = 1,
    per_page = 10,
    category_ids,
    price_from,
    price_to,
    start_from,
    start_to,
    end_from,
    end_to,
  }) {
    const { data } = await axios.get(`${orderURL}/underwriter`, {
      params: { page, per_page, category_ids, price_from, price_to, start_from, start_to, end_from, end_to },
    })
    return data
  },

  exportOrderPolisByOrderId(orderId) {
    window.open(`${baselURL}/policy?order_id=${orderId}`, '_blank')
  },

  async getPreset(presetType) {
    const { data } = await axios.get(`${orderURL}/order-form/presets/${presetType}`)
    handleError(data)
    return data
  },

  async loadTemplate({ templateType }) {
    const { data } = await axios.get(`${orderURL}/order-form/templates/${templateType}`)
    return data
  },

  async createForm(form) {
    const { data } = await axios.post(`${orderURL}/order-form`, form)
    return data
  },

  async getOrderForm(orderId) {
    const { data } = await axios.get(`${orderURL}/order-form/${orderId}`)
    return data
  },

  async updateForm(id, form) {
    const { data } = await axios.post(`${orderURL}/order-form/${id}`, form)
    handleError(data)
    return data
  },

  async updateFormElement(formId, element) {
    const { data } = await axios.post(`${orderURL}/order-form/${formId}/input/${element.id}`, element)
    handleError(data)
    return data
  },

  async getSelectOptionsByType(selectType, keyword = '', limit = 10, offset = 0) {
    const { data } = await axios.get(`${orderURL}/order-form/input/${selectType}/options`, {
      params: {
        limit,
        keyword,
        offset,
      },
    })

    return data
  },

  async getSelectOptionsByTypeAbortable({ selectType, keyword = '', limit = 10, offset = 0, signal = null }) {
    const { data } = await axios.get(`${orderURL}/order-form/input/${selectType}/options`, {
      params: {
        limit,
        keyword,
        offset,
      },
      signal,
    })

    return data
  },

  async getOrderFormView(orderId) {
    const { data } = await axios.get(`${orderURL}/order-form/${orderId}/view`)
    handleError(data)
    return data
  },

  // OFFERS

  async getAllOffersForm(formId) {
    const { data } = await axios.get(`${orderURL}/offers/${formId}`)

    handleError(data)
    return data
    // return Mocks.getOffers(formId)
  },

  async createOffer({ formId, offer, userId }) {
    const { data } = await axios.post(`${orderURL}/offers/${formId}/user/${userId}`, offer)

    return data
  },

  async updateOffer(offerId, offer) {
    const { data } = await axios.post(`${orderURL}/offers/${offerId}/object`, offer)

    return data
  },

  async getOfferObject(offerId) {
    const { data } = await axios.get(`${orderURL}/offers/${offerId}/object`)

    return data
  },

  async getOfferPresetForForm(formId) {
    const { data } = await axios.get(`${orderURL}/offers/${formId}/preset`)

    handleError(data)
    return data
  },

  async getOfferForm(formId, offerId) {
    const { data } = await axios.get(`${orderURL}/offers/${formId}/${offerId}/form`)

    handleError(data)
    return data
  },

  async getOfferView(formId, offerId) {
    const { data } = await axios.get(`${orderURL}/offers/${formId}/${offerId}/view`)

    handleError(data)
    return data
  },

  async getRequiredOfferView(offerId, hide_empty = 0) {
    const { data } = await axios.get(`${orderURL}/required/${offerId}/view`, {
      params: { hide_empty: Number(hide_empty) },
    })

    handleError(data)

    return data
  },

  async getRequiredOfferForm(offerId) {
    const { data } = await axios.get(`${orderURL}/required/${offerId}/form`)

    handleError(data)

    return data
  },

  async createRequiredFieldsOfOffer(offerId, fields) {
    const { data } = await axios.post(`${orderURL}/required/${offerId}`, fields)

    handleError(data)

    return data
  },

  // NOTES

  async createNoteForRow({ formId, rowId, userId, text }) {
    const { data } = await axios.post(`${orderURL}/order-form/${formId}/notes`, {
      form_id: formId,
      form_row_id: rowId,
      user_id: userId,
      text,
    })

    handleError(data)

    return data
  },

  async getAllNotesForFormById(formId) {
    const { data } = await axios.get(`${orderURL}/order-form/${formId}/notes`)

    handleError(data)
    return data
    // return Mocks.getNotes(formId)
  },

  async deleteNoteFromFormById(formId, noteId) {
    const { data } = await axios.delete(`${orderURL}/order-form/${formId}/notes/${noteId}`)

    return data
  },

  async getComparedForms(mainFormId, comparableFormId, insurerId) {
    const { data } = await axios.get(`${orderURL}/order-form/${mainFormId}/compare/${comparableFormId}`, {
      params: { user_id: insurerId },
    })

    handleError(data)

    return data
  },

  async getFormViewByIdAndName(formId, fieldName) {
    const { data } = await axios.get(`${orderURL}/order-form/forms/${formId}/view`, {
      params: { field_name: fieldName },
    })

    handleError(data)

    return data
  },
}
