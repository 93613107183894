<template>
  <v-menu
    ref="dateMenuRef"
    :disabled="element.disabled"
    v-model="dateMenuIsOpen"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="fieldToValidate"
        v-model="dateFormatted"
        v-mask="'##.##.####'"
        :background-color="bgColor"
        class="input rounded-lg"
        hint="Укажите дату"
        placeholder="__.__.____"
        :append-icon="element.disabled ? 'mdi-lock' : 'mdi-calendar'"
        filled
        outlined
        dense
        :rules="rules"
        :disabled="element.disabled"
        validate-on-blur
        v-bind="attrs"
        v-on="on"
        @blur="date = parseDate(dateFormatted)"
        @keyup.enter="$refs.dateMenuRef.save(date)"
      />
    </template>
    <v-date-picker locale="ru-RU" v-model="date" no-title scrollable color="primary">
      <v-spacer />
      <v-btn text color="primary" @click="dateMenuIsOpen = false"> Отмена </v-btn>
      <v-btn text color="primary" @click="$refs.dateMenuRef.save(date)"> Сохранить </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { inputRules, updateValueMixin } from '../mixins'

export default {
  name: 'form-date',
  mixins: [updateValueMixin('dateFormatted', 'value'), inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dateMenuIsOpen: false,
      dateFormatted: '',
      date: '',
    }
  },

  created() {
    if (this.element.value) {
      this.data = this.parseDate(this.element.value)
      this.dateFormatted = this.element.value
    }
  },

  watch: {
    date(v) {
      this.dateFormatted = this.formatDate(v)
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>
