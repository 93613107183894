<template>
  <v-menu bottom :close-on-content-click="false" max-width="375px" offset-y v-model="isOpen">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-if="!disabled"
        v-bind="attrs"
        v-on="on"
        class="chatted col"
        :class="{ 'chatted--active': isOpen, 'chatted--has-notes': filteredNotes.length > 0 }"
      >
        <slot></slot>

        <ViewEditRow v-if="editable && filteredNotes.length > 0" :row="row" />
      </div>
      <div v-else class="no-chatted">
        <slot></slot>
      </div>
    </template>
    <v-card outlined class="rounded-lg overflow-hidden">
      <v-list ref="msgContainer" color="#F4F7F9" height="350px" class="scrolled">
        <v-list-item v-for="note in filteredNotes" :key="note.id">
          <v-list-item-content>
            <v-sheet rounded="lg" class="pa-3 white bordered" outlined>
              <v-list-item-title class="text-size-7">
                <b>{{ note.companyName }}</b> {{ note.authorName }}
              </v-list-item-title>
              <v-list-item-subtitle class="mb-2 text-size-6">{{ note.createdAt }}</v-list-item-subtitle>
              <p class="text-size-7">{{ note.text }}</p>
            </v-sheet>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-textarea
        v-model="currentNoteText"
        filled
        outlined
        color="#F3F6F8"
        background-color="#F3F6F8"
        class="mx-3 mt-4 mb-2"
        placeholder="Напишите примечание"
        rows="2"
        hide-details
        @keypress.enter.prevent="addNote()"
      />
      <v-card-actions class="d-flex">
        <v-btn outlined color="grey" class="flex-grow-1" @click="close()"> Отмена</v-btn>
        <v-btn :loading="loading || notesLoading" color="primary" class="flex-grow-1" @click="addNote()">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import ViewEditRow from '@/components/view-block/components/ViewEditRow.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  inject: ['editable'],
  components: { ViewEditRow },
  props: {
    row: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      currentNoteText: '',
    }
  },
  methods: {
    addNote() {
      if (this.currentNoteText.length > 0) {
        this.createNote({ text: this.currentNoteText, rowId: this.row.id })
        this.currentNoteText = ''
        this.scrollToBottom()
      }
    },
    close() {
      this.isOpen = false
      this.currentNoteText = ''
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.msgContainer.$el
        const lastChild = container.lastChild
        if (lastChild) {
          lastChild.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      })
    },
    ...mapActions({
      createNote: 'notes/createNote',
    }),
  },
  computed: {
    ...mapGetters({
      notesByRowId: 'notes/notesByRowId',
      notesByRowAndInsurerId: 'notes/notesByRowAndInsurerId',
      notesLoading: 'notes/loading',
    }),
    filteredNotes() {
      const { insurerId } = this.$route.params
      if (insurerId) {
        return this.notesByRowAndInsurerId(this.row.id, insurerId)
      }
      return this.notesByRowId(this.row.id)
    },
  },
}
</script>
<style lang="scss" scoped>
.v-list.scrolled {
  overflow: scroll !important;
  border-bottom: 1px solid #e6eaed !important;
}

.overflow-hidden {
  overflow: hidden;
}

.no-chatted {
  flex-grow: 1;
  display: flex;
}

.chatted {
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 0;

  &:hover {
    outline: 1px solid #039a9a;

    &::after {
      border-color: transparent #039a9a transparent transparent;
    }
  }

  ul {
    max-width: 700px;
  }

  &--has-notes {
    outline: 1px solid #eaaa08;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 15px 0;
      border-color: transparent #eaaa08 transparent transparent;
    }
  }

  &--active {
    background: linear-gradient(281.99deg, rgba(3, 154, 154, 0.1) 10.78%, rgba(21, 187, 187, 0.1) 66.87%);
    outline: 1px solid #039a9a;

    &::after {
      border-color: transparent #039a9a transparent transparent;
    }

    &::before {
      background: linear-gradient(281.99deg, rgba(3, 154, 154, 0.1) 10.78%, rgba(21, 187, 187, 0.1) 66.87%);
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-color: transparent #039a9a transparent transparent;
    }
  }
}
</style>
