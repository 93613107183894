import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import Vue from 'vue'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

function dateFormat(d, format = 'DD.MM.YYYY', parseType) {
  if (parseType) {
    return dayjs(d, parseType, 'ru', true).format(format)
  }

  return dayjs(d).format(format)
}

Vue.filter('dateFormat', dateFormat)
