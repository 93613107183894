export function findRecursive(elems, uuid) {
  for (const el of elems) {
    if (el.uuid == uuid) {
      return el
    }

    if (!el.elements) {
      continue
    } else {
      const found = findRecursive(el.elements, uuid)
      if (found) {
        return found
      }
    }
  }

  return null
}
