<template>
  <div class="button-wrap">
    <v-btn
      :loading="loading"
      elevation="0"
      text
      color="primary"
      class="mb-2 mr-2 text-capitalize text-teal"
      @click="downloadFile()"
    >
      <v-icon class="mr-1">mdi-download</v-icon> {{ element.placeholder }}
    </v-btn>
  </div>
</template>
<script>
import { showFile } from '@/api/fileAPI'

export default {
  name: 'form-file-download',
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },

  created() {
    if (this.element.value) {
      this.filePath = this.element.value
      this.fileName = 'Document.docx'
    }
  },

  methods: {
    async downloadFile() {
      if (this.loading) {
        return
      }

      this.loading = true
      try {
        const { data, headers } = await showFile(this.element.placeholder)
        let file = new Blob([data], { type: headers['content-type'] })
        let fileUrl = URL.createObjectURL(file)
        window.open(fileUrl, '_blank')
      } catch (error) {
        console.error(error)
        this.$alerts.error({ message: 'Ошибка загрузки файла:' + error })
      }

      this.loading = false
    },
  },
}
</script>
