<template>
  <v-row v-if="!element.is_hidden">
    <v-col>
      <v-divider />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-row-separator',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
