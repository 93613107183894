<template>
  <v-row v-if="!element.is_hidden">
    <v-col cols="8">
      <v-sheet color="grey lighten-4 pa-3" rounded="lg">
        <div class="d-flex justify-end">
          <v-btn v-if="isRemovable(element.uuid)" icon color="red" @click="removeElement(element)">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-container>
          <component
            v-for="row in element.elements"
            :key="row.uuid"
            bg-color="#fff"
            cols="6"
            :element="row"
            :is="row.type | detectComponent"
          />
        </v-container>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { detectComponent } from '../filters'

export default {
  name: 'form-group-two',
  inject: ['moduleName'],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  filters: {
    detectComponent,
  },
  data() {
    return {}
  },

  methods: {
    removeElement(el) {
      this.$store.commit(`${this.moduleName}/REMOVE_ELEMENT`, el)
    },
  },

  computed: {
    isRemovable() {
      return this.$store.getters[`${this.moduleName}/isRemovable`]
    },
  },
}
</script>
