<template>
  <v-row v-if="!element.is_hidden">
    <v-col :cols="cols">
      <label class="label text-subtitle-2">
        {{ element.name }} <span v-show="isRequiredRow" class="text text-red">*</span>
      </label>
    </v-col>
    <v-col
      :cols="cols"
      class="py-0 rounded-lg"
      :class="{ 'bordered-error': showErrorBorder && (isElementsRadio || isElementsCheckbox || isElementsFile) }"
    >
      <template v-if="isElementsRadio">
        <form-radio :element="element" />
      </template>
      <template v-else>
        <component
          v-for="el in element.elements"
          :element="el"
          :bg-color="bgColor"
          :key="el.uuid"
          :is="el.type | detectComponent"
        />
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { Inputs } from '../types'
import { detectComponent } from '../filters'
import { checkRequiredFieldsRecursive } from '@/utils/checkRequiredFieldsRecursive'

export default {
  name: 'form-row-horizontal-input',
  props: {
    cols: {
      type: String,
      default: '4',
    },
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  filters: {
    detectComponent,
  },
  data() {
    return {}
  },
  computed: {
    isElementsRadio() {
      return this.element.elements.every((el) => el.type == Inputs.RADIO)
    },
    isElementsCheckbox() {
      return this.element.elements.every((el) => el.type == Inputs.CHECKBOX)
    },
    isElementsFile() {
      return this.element.elements.every((el) => el.type == Inputs.FILE)
    },
    isRequiredRow() {
      if (this.element.disabled || this.element.elements.some((el) => el.disabled)) {
        return false
      }

      return this.element.required || this.element.elements.some((e) => e.required)
    },
    showErrorBorder() {
      if (this.element.disabled || this.element.elements.some((el) => el.disabled)) {
        return false
      }
      if (this.element.required) {
        const invalidElements = checkRequiredFieldsRecursive({
          elems: this.element.elements,
          required: this.element.required,
        })

        return invalidElements.length == this.element.elements.length
      }

      return false
    },
  },
}
</script>
<style lang="scss">
.bordered-error {
  outline: 1px solid $accent_color;
}
</style>
