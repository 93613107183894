<template>
  <v-text-field
    ref="fieldToValidate"
    v-model="email"
    type="email"
    :background-color="bgColor"
    :disabled="element.disabled"
    filled
    class="rounded-lg text-subtitle-2"
    outlined
    dense
    :rules="rules"
    validate-on-blur
    placeholder="Укажите email"
    :append-icon="element.disabled ? 'mdi-lock' : ''"
  />
</template>
<script>
import { inputRules, updateValueMixin } from '../mixins'

export default {
  name: 'form-email',
  mixins: [updateValueMixin('email', 'value'), inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
    }
  },

  created() {
    if (this.element.value) {
      this.email = this.element.value
    }
  },
}
</script>
