export const Inputs = {
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  TEXT: 'Text',
  TEXTAREA: 'TextArea',
  EMAIL: 'Email',
  PHONE: 'Phone',
  SELECT_SINGLE: 'SelectSingle',
  SELECT_MULTIPLE: 'SelectMultiple',
  ID_NUMBER: 'IDNumber',
  DATE: 'Date',
  FILE: 'File',
  SELECT_COUNTRY: 'SelectCountry',
}

export const FormComponents = {
  Radio: 'form-radio',
  Checkbox: 'form-checkbox',
  Text: 'form-text',
  TextArea: 'form-textarea',
  Email: 'form-email',
  Phone: 'form-phone',
  SelectSingle: 'form-select-single',
  SelectCountry: 'form-select-country-single',
  SelectEconomySector: 'form-select-single',
  SelectInsurerType: 'form-select-single',
  SelectMultiple: 'form-select-multiple',
  SelectCountryMultiple: 'form-select-country-multiple',
  IDNumber: 'form-id-number',
  Date: 'form-date',
  File: 'form-file',
  FileWithExample: 'form-file-download',
  Header: 'form-row-header',
  HorizontalInput: 'form-row-horizontal-input',
  VerticalInput: 'form-row-vertical-input',
  Separator: 'form-row-separator',
  Button: 'form-row-button',
  ButtonContainer: 'form-row-button-container',
  SubmitButton: 'form-row-submit-button',
  UploadedFile: 'form-row-uploaded-file',
  Type1: 'form-group-one',
  Type2: 'form-group-two',
  Type3: 'form-group-three',
  TypeTransparent: 'form-group-transparent',
  InformationGray: 'form-row-information-gray',
  DateYear: 'form-date-year',
  DateYearMultiple: 'form-date-year-multiple',
  AddFileButton: 'form-add-file-button',
}

export const Rows = {
  HEADER: 'Header',
  HORIZONTAL_INPUT: 'HorizontalInput',
  VERTICAL_INPUT: 'VerticalInput',
  SEPARATOR: 'Separator',
  BUTTON: 'Button',
  SUBMIT_BUTTON: 'SubmitButton',
  UPLOADED_FILE: 'UploadedFile',
}

export const Groups = {
  TYPE_1: 'Type1',
  TYPE_2: 'Type2',
  TYPE_3: 'Type3',
  TYPE_TRANSPARENT: 'TypeTransparent',
}
