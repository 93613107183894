import Vue from 'vue'

const statuses = {
  1: 'Ожидает оплаты',
  2: 'Оплачено',
  3: 'Черновик',
  4: 'Отменен',
  5: 'На рассмотрении',
  6: 'Заявление-анкета',
  7: 'На утверждении',
  8: 'На подписании',
  9: 'Ожидает оплаты (рассрочка)',
  10: 'Оплачено (рассрочка)',
  11: 'Заявление-анкета (выбор полей)',
  12: 'Заявление-анкета (заполнение)',
}

export function getOrderStatusText(statusId) {
  if (statusId in statuses) {
    return statuses[statusId]
  }

  return '-'
}

Vue.filter('getOrderStatusText', getOrderStatusText)
