<template>
  <v-row v-if="!element.is_hidden">
    <v-col>
      <label class="label text-subtitle-2"> {{ element.name }} </label>
    </v-col>
    <v-col>
      <v-btn elevation="0" color="rgba(3, 154, 154, 0.1)" class="mr-2 text-capitalize text-teal">
        <v-icon small class="mr-2">mdi-tray-arrow-up</v-icon> Загрузить файл
      </v-btn>
      <v-btn elevation="0" text plain class="mr-2 text-capitalize text-teal">
        Document.docx <v-icon small class="ml-2">mdi-window-close</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-row-uploaded-file',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
