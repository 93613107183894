<template>
  <v-select
    ref="fieldToValidate"
    :items="items"
    :loading="loading"
    v-model="value"
    :disabled="element.disabled"
    no-data-text="Данных нет"
    filled
    :background-color="bgColor"
    class="rounded-lg text-subtitle-2"
    outlined
    dense
    placeholder="Укажите значение"
    clearable
    :rules="rules"
    validate-on-blur
    :append-icon="element.disabled ? 'mdi-lock' : 'mdi-chevron-down'"
    @click:clear="handleChange"
    @change="handleChange"
  />
</template>
<script>
import { inputRules } from '../mixins'

export default {
  name: 'form-date-year-multiple',
  inject: ['moduleName'],
  mixins: [inputRules()],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],

      value: null,
    }
  },

  created() {
    this.getValues()
  },

  methods: {
    async getValues() {
      if (this.loading) {
        return
      }

      this.loading = true
      try {
        const values = (this.element.value || '').split(',')

        this.value = values

        this.items = this.generateValues()
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    handleChange() {
      this.updateElement({
        uuid: this.element.uuid,
        key: 'value',
        value: this.value,
      })
    },

    updateElement(el) {
      this.$store.commit(`${this.moduleName}/UPDATE_ELEMENT`, el)
    },

    generateValues() {
      const res = []
      for (let i = 2022; i >= 1900; i--) {
        res.push(i)
      }

      return res
    },
  },
}
</script>
