import Vue from 'vue'

import ViewGroupOneVue from './groups/ViewGroupOne.vue'
import ViewGroupTwoVue from './groups/ViewGroupTwo.vue'
import ViewGroupThree from './groups/ViewGroupThree.vue'
import ViewGroupTransparent from './groups/ViewGroupTransparent.vue'
import ViewHorizontalInput from './rows/ViewHorizontalInput.vue'
import ViewHorizontalInputWithChat from './rows/ViewHorizontalInputWithChat.vue'
import ViewBlock from './ViewBlock.vue'

Vue.component('view-group-one', ViewGroupOneVue)
Vue.component('view-group-two', ViewGroupTwoVue)
Vue.component('view-group-three', ViewGroupThree)
Vue.component('view-group-transparent', ViewGroupTransparent)
Vue.component('view-horizontal-input', ViewHorizontalInput)
Vue.component('view-horizontal-input-with-chat', ViewHorizontalInputWithChat)
Vue.component('view-group-one', ViewGroupOneVue)
Vue.component('view-block', ViewBlock)
