<template>
  <v-checkbox
    class="my-0"
    v-model="value"
    :label="element.title"
    :disabled="element.disabled"
    :append-icon="element.disabled ? 'mdi-lock' : ''"
  />
</template>
<script>
import { updateValueMixin } from '../mixins'

export default {
  name: 'form-checkbox',
  mixins: [updateValueMixin('value', 'is_checked')],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.value = this.element.is_checked
  },
  data() {
    return {
      value: false,
    }
  },
}
</script>
