<template>
  <div class="group-transparent">
    <component v-for="row in element.data" :key="row.id" :element="row" :is="getComponent(row.type)" />
  </div>
</template>
<script>
import { ComponentDetectionMixin } from '../mixins'

export default {
  name: 'view-comments-group-transparent',
  mixins: [ComponentDetectionMixin],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
