<template>
  <div class="alerts">
    <AlertItem v-for="alert in alerts" :key="alert.id" :alert="alert" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AlertItem from './AlertItem.vue'

export default {
  components: { AlertItem },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/data',
    }),
  },
}
</script>
<style lang="scss" scoped>
.alerts {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  max-width: 40%;
  z-index: 100;
}
</style>
