<template>
  <div class="button-wrap">
    <input ref="fileInput" type="file" hidden @change="handleUpload()" :disabled="element.disabled" />
    <v-btn
      v-if="!filePath"
      :loading="loading"
      elevation="0"
      color="rgba(3, 154, 154, 0.1)"
      class="mb-2 mr-2 text-capitalize text-teal"
      @click="$refs.fileInput.click()"
      :disabled="element.disabled"
    >
      <v-icon small class="mr-2">mdi-tray-arrow-up</v-icon> Загрузить файл
    </v-btn>
    <v-btn v-else :loading="loading" elevation="0" text color="primary" class="mb-2 mr-2 text-capitalize text-teal">
      {{ fileName || 'Document' }}
      <v-icon v-if="!element.disabled" small class="ml-2" @click="handleDelete()">mdi-window-close</v-icon>
    </v-btn>

    <v-btn v-if="isRemovable(element.uuid)" icon color="red" @click="removeElement(element)">
      <v-icon>mdi-window-close</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { storeFile, destroyFile } from '@/api/fileAPI'
import { updateValueMixin } from '../mixins'

export default {
  name: 'form-file',
  mixins: [updateValueMixin('filePath', 'value')],
  props: {
    bgColor: {
      type: String,
      default: '#F3F6F8',
    },
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filePath: '',
      fileName: '',
      loading: false,
    }
  },

  created() {
    if (this.element.value) {
      this.filePath = this.element.value
      this.fileName = 'Document.docx'
    }
  },

  methods: {
    async handleUpload() {
      if (this.loading) {
        return
      }

      this.loading = true
      try {
        const { files } = this.$refs.fileInput
        const [file] = files

        this.fileName = file.name

        const fd = new FormData()
        fd.append('file', file)
        const { data } = await storeFile(fd)
        this.filePath = data.data
      } catch (error) {
        this.$alerts.error({ message: 'Ошибка загрузки файла', error })
      }

      this.loading = false
    },

    async handleDelete() {
      if (this.loading) {
        return
      }

      this.loading = true
      try {
        await destroyFile(this.filePath)

        this.fileName = ''
        this.filePath = ''
      } catch (error) {
        console.error(error)
        this.$alerts.error({ message: 'Ошибка загрузки файла:' + error })
      }

      this.loading = false
    },

    removeElement(el) {
      this.$store.commit(`${this.moduleName}/REMOVE_ELEMENT`, el)
    },
  },

  computed: {
    isRemovable() {
      return this.$store.getters[`${this.moduleName}/isRemovable`]
    },
  },
}
</script>
