export function findRecursiveById(elems, id) {
  for (const el of elems) {
    if (el.id == id) {
      return el
    }

    if (!el.elements) {
      continue
    } else {
      const found = findRecursiveById(el.elements, id)
      if (found) {
        return found
      }
    }
  }

  return null
}
