<template>
  <component :element="element" :is="getComponent(element.type)" />
</template>
<script>
import { ComponentDetectionMixin } from './mixins'

export default {
  mixins: [ComponentDetectionMixin],

  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
