<template>
  <v-row v-if="!element.is_hidden">
    <v-col>
      <v-sheet color="#D2EDED" class="overflow-hidden" rounded="lg" outlined>
        <h6 v-if="element.header" class="text-subtitle-2 font-weight-bold pa-3">{{ element.header }}</h6>
        <v-sheet elevation="0" color="pa-3">
          <v-container fluid>
            <component
              v-for="row in element.elements"
              :key="row.uuid"
              :element="row"
              :is="row.type | detectComponent"
            />
          </v-container>
        </v-sheet>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { detectComponent } from '../filters'

export default {
  name: 'form-group-three',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  filters: {
    detectComponent,
  },
  data() {
    return {}
  },
}
</script>
