<template>
  <component :element="element" :is="getComponent(element.type)" />
</template>
<script>
import { computed } from 'vue'
import { ComponentDetectionMixin } from './mixins'

export default {
  mixins: [ComponentDetectionMixin],

  provide() {
    return {
      showCheckboxes: computed(() => this.showCheckboxes),
      showComments: computed(() => this.showComments),
      editable: computed(() => this.editable),
    }
  },

  props: {
    element: {
      type: Object,
      required: true,
    },

    showCheckboxes: {
      type: Boolean,
      required: false,
      default: false,
    },

    showComments: {
      type: Boolean,
      required: false,
      default: false,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
