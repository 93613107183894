export function findAndRemoveRecursive({ elems, uuid }) {
  let index = 0
  for (const el of elems) {
    if (el.uuid == uuid) {
      elems.splice(index, 1)
      return true
    }

    if (!el.elements) {
      continue
    } else {
      const found = findAndRemoveRecursive({ elems: el.elements, uuid })
      if (found) {
        return found
      }
    }

    index++
  }

  return false
}
