<template>
  <v-row v-if="!element.is_hidden">
    <v-col>
      <v-sheet color="grey lighten-4 pa-4" rounded="lg">
        <p class="text-size-7 font-weight-bold">{{ element.name }}</p>
        <p class="text-size-7">{{ element.description }}</p>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-row-information-gray',
  props: {
    element: {
      type: Object,
      required: true,
    },
    cols: {
      type: String,
      default: '8',
    },
  },
}
</script>
