import { userURL } from './baseURL'
import axios from 'axios'

export default {
  getProfileInfo() {
    return axios.get(`${userURL}/user`)
  },
  updateUser(userData) {
    return axios.post(`${userURL}/user/update`, userData)
  },
  async getUserById(userId) {
    const { data } = await axios.get(`${userURL}/users/${userId}`)

    return data
  },
}
