import { productURL } from './baseURL'
const axios = require('axios')

export default {
  getCategories() {
    return axios.get(`${productURL}/category`)
  },
  getProducts(query) {
    return axios.get(`${productURL}/product`, {
      params: query,
    })
  },
  async getPrductByProductId(productId) {
    const { data } = await axios.get(`${productURL}/product/${productId}`)
    return data
  },
}
