import orderAPI from '@/api/orderAPI'
import productAPI from '@/api/productAPI'
import { Alerts } from '@/plugins/alerts'

const getState = () => ({
  view: {
    id: 0,
    order_id: 0,
    created_at: '',
    updated_at: '',
    data: [],
  },

  order: {
    id: 0,
    product_id: 0,
    user_id: 0,
    product_type: 'underrater',
    accident_count: 0,
    fix_sum: null,
    email: null,
    phone: null,
    deleted_at: null,
    created_at: '',
    updated_at: '',
    key: 'autokasko',
    status_id: 3,
    status: '',
    ogpo: null,
    prices: [],
  },

  product: {
    id: 0,
    deleted_at: null,
    created_at: null,
    updated_at: null,
    name: '',
    type: '',
    description: '',
    is_legal: false,
    percentage_remuneration: '',
    image: '',
    code: '',
    key: '',
    category: {
      id: 0,
      name: '',
      sys_name: '',
    },
  },

  loadingKeys: {},
})

const MUTATION_TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_ORDER: 'SET_ORDER',
  SET_VIEW: 'SET_VIEW',
  RESET_STATE: 'RESET_STATE',
  SET_PRODUCT: 'SET_PRODUCT',
}

const mutations = {
  [MUTATION_TYPES.SET_ORDER](state, order) {
    state.order = order
  },
  [MUTATION_TYPES.SET_VIEW](state, view) {
    state.view = view
  },
  [MUTATION_TYPES.SET_LOADING](state, { key, loading }) {
    state.loadingKeys = { ...state.loadingKeys, [key]: loading }
  },

  [MUTATION_TYPES.RESET_STATE](state) {
    Object.assign(state, getState())
  },

  [MUTATION_TYPES.SET_PRODUCT](state, product) {
    state.product = product
  },
}

const actions = {
  async getOrder({ commit }, { orderId }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'order', loading: true })

    try {
      const { data } = await orderAPI.getOneOrderById(orderId)

      commit(MUTATION_TYPES.SET_ORDER, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'order', loading: false })
  },

  async getOrderFormView({ commit }, { orderId }) {
    commit(MUTATION_TYPES.SET_LOADING, { key: 'view', loading: true })

    try {
      const { data } = await orderAPI.getOrderFormView(orderId)

      commit(MUTATION_TYPES.SET_VIEW, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка загрузки заявки', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { key: 'view', loading: false })
  },

  async getProduct({ commit, state }) {
    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'product', loading: true })

    try {
      const { data } = await productAPI.getPrductByProductId(state.order.product_id)
      commit(MUTATION_TYPES.SET_PRODUCT, data)
    } catch (error) {
      Alerts.error({ message: 'Ошибка при загрузки продукта', error })
    }

    commit(MUTATION_TYPES.SET_LOADING, { uuid: 'product', loading: false })
  },
}

const getters = {
  getLoadingFor({ loadingKeys }) {
    return (key) => Boolean(loadingKeys[key])
  },

  order({ order }) {
    return order
  },

  view({ view }) {
    return view
  },

  product({ product }) {
    return product
  },
}

export default {
  namespaced: true,
  state: getState,
  mutations,
  actions,
  getters,
}
