export function checkRequiredFieldsRecursive({ elems, required = false }) {
  let requredElems = []
  for (const el of elems) {
    if (el.is_hidden || el.disabled) {
      continue
    }

    if (el.required || required) {
      if (Array.isArray(el.elements)) {
        if (el.elements.every((e) => e.type == 'Checkbox' || e.type == 'Radio')) {
          if (el.elements.every((e) => !e.is_checked && !e.disabled)) {
            requredElems.push(el)
          }
        } else {
          const foundElems = checkRequiredFieldsRecursive({ elems: el.elements, required: el.required })

          requredElems = requredElems.concat(foundElems)
        }
      } else {
        if (el.type == 'Checkbox' || el.type == 'Radio') {
          if (!el.is_checked) {
            requredElems.push(el)
          }
        } else if (!el.value) {
          requredElems.push(el)
        } else if (Array.isArray(el.value) && el.value.every((v) => !v.selected)) {
          el.value.every((v) => !v.selected)

          requredElems.push(el)
        }
      }
    } else if (Array.isArray(el.elements)) {
      const foundElems = checkRequiredFieldsRecursive({ elems: el.elements })

      requredElems = requredElems.concat(foundElems)
    }
  }

  return requredElems
}
