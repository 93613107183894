<template>
  <v-row>
    <v-col>
      <v-sheet color="#D2EDED" class="overflow-hidden" rounded="lg" outlined>
        <h6 v-if="element.header" class="text-subtitle-2 font-weight-bold pa-3">{{ element.header }}</h6>
        <v-sheet elevation="0" color="pa-3">
          <v-container fluid>
            <component v-for="row in element.data" :key="row.id" :element="row" :is="getComponent(row.type)" />
          </v-container>
        </v-sheet>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { ComponentDetectionMixin } from '../mixins'

export default {
  name: 'view-group-three',
  mixins: [ComponentDetectionMixin],
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>
