import orderAPI from '@/api/orderAPI'
import { Alerts } from '@/plugins/alerts'
import { findRecursive } from '@/utils/findRecursive'
import Vue from 'vue'

const state = () => ({
  element: {
    id: 0,
    value: '',
    elements: [],
  },

  updatedUuid: null,
})

const MUTATION_TYPES = {
  UPDATE_ELEMENT: 'UPDATE_ELEMENT',
  SET_ELEMENT: 'SET_ELEMENT',
}

const mutations = {
  [MUTATION_TYPES.UPDATE_ELEMENT](state, { uuid, key, value }) {
    const elem = findRecursive(state.element.elements || [], uuid)

    if (elem) {
      Vue.set(elem, key, value)
      state.updatedUuid = uuid
    }
  },
  [MUTATION_TYPES.SET_ELEMENT](state, el) {
    state.element = {
      ...el,
      elements: [
        ...el.elements.map((e) => {
          if (e.disabled) {
            return { ...e, disabled: false, wasDisabled: true }
          }

          return e
        }),
      ],
    }
  },
}
const actions = {
  async submitElement({ state, rootState }) {
    try {
      let element = findRecursive(state.element.elements, state.updatedUuid)
      if (!element) throw new Error('Нет обновляемого елемента')

      if (element.wasDisabled) {
        element = { ...element, disabled: true, wasDisabled: undefined }
      }
      await orderAPI.updateFormElement(rootState.orderView.view.id, element)

      Alerts.success({ message: `Поле: ${state.element.name} успешно обновлено!` })
    } catch (error) {
      Alerts.error({ message: 'Не удалось обновить строку', error })
    }
  },
}

const getters = {
  element({ element }) {
    return element
  },
  getActionsListByUUID() {
    return () => []
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
