<template>
  <v-row class="mb-3">
    <v-col cols="4" class="mr-3" :class="{ 'font-weight-bold': element.is_bold_name }"> {{ element.name }} </v-col>

    <ChatDropdown :row="element">
      <v-col>{{ flattedData }} </v-col>
    </ChatDropdown>
  </v-row>
</template>
<script>
import ChatDropdown from '@/views/offers-insurer/components/ChatDropdown.vue'

export default {
  name: 'view-horizontal-input-with-chat',
  components: { ChatDropdown },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },

  computed: {
    flattedData() {
      return (this.element.data || []).flat().join(', ')
    },
  },
}
</script>
