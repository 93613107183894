import { fileURL } from '@/api/baseURL'
import { saveFile } from '@/utils/saveFile'
import axios from 'axios'
import path from 'path'

async function storeFile(data) {
  return await axios.post(`${fileURL}/file/store`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

async function showFile(link, responseType = 'blob') {
  return await axios.post(
    `${fileURL}/file/show`,
    { link },
    {
      responseType,
    }
  )
}

async function downloadFileByLink(link) {
  const { data, headers } = await axios.post(
    `${fileURL}/file/show`,
    { link },
    {
      responseType: 'blob',
    }
  )

  let file = new Blob([data], { type: headers['content-type'] })
  let fileUrl = URL.createObjectURL(file)
  window.open(fileUrl, '_blank')
}

async function downloadFileByLinkAnchor(link) {
  const fileName = path.parse(link)
  const { data, headers } = await axios.post(
    `${fileURL}/file/show`,
    { link },
    {
      responseType: 'blob',
    }
  )

  const file = new File([data], { type: headers['content-type'] })
  saveFile(file, fileName.base)
}

async function destroyFile(link) {
  return await axios.post(`${fileURL}/file/destroy`, { link })
}

export { storeFile, showFile, destroyFile, downloadFileByLink, downloadFileByLinkAnchor }
